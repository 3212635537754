import RightArc from '@/assets/icons/RightArc'
import { Input } from '@/components/ui/input'
import { routes } from '@/routes/utils'
import { cn } from '@/utils/shadcn'
import { ChevronsLeft, Search, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ThemesQueryQuery } from '@/gql/graphql'
import useDebounceValue from '@/hooks/useDebounceValue'
import SearchPopup from './SearchPopup'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'

const SearchInput = ({
  className,
  themes,
}: {
  themes: ThemesQueryQuery['themes']
  className?: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const q = searchParams.get('q') || ''
  const [search, setSearch] = useState('')
  const debounceSearch = useDebounceValue(search, 750)
  const [openPopup, setOpenPopup] = useState(false)
  const showSearchIcon =
    debounceSearch !== q || (pathname !== routes.searchPage.getPath() && debounceSearch)
  const { width } = useWindowDimensions()

  useEffect(() => {
    setSearch(q)
  }, [q])

  const handleClearSearch = () => {
    setSearch('')
    if (pathname === routes.searchPage.getPath()) {
      navigate(routes.searchPage.getPath())
    }
  }

  const handleSearch = () => {
    if (search.length < 2) return
    navigate(`${routes.searchPage.getPath()}?q=${search}`)
  }

  if (width < 768) {
    return (
      <Sheet>
        <SheetTrigger asChild>
          <div className="relative mr-5">
            <button className="pt-2 hover:text-brand-torea" onClick={() => setOpenPopup(true)}>
              <Search size={20} />
            </button>
          </div>
        </SheetTrigger>
        <SheetContent side="bottom" className="h-full p-0 [&>.close-button]:hidden">
          <div className="fixed left-0 top-0 flex w-full items-center gap-3 p-3 bg-search">
            <SheetTrigger asChild className="shrink-0">
              <ChevronsLeft size={22} />
            </SheetTrigger>
            <div className="relative flex-1">
              <Input
                icon={<Search size={20} className="text-deep-cove/50" />}
                placeholder={t('search_placeholder')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onEnter={handleSearch}
                className="w-full"
                inputClassName="bg-cararra border-none placeholder:text-deep-cove/50 caret-deep-cove hover:bg-search duration-300"
                hideLabel
              />
              {debounceSearch && !showSearchIcon && (
                <button
                  className={cn(
                    'absolute right-3 top-1/2 z-10 grid h-4 w-4 -translate-y-1/2 place-items-center rounded-full bg-deep-cove/25 text-white',
                  )}
                  onClick={handleClearSearch}
                >
                  <X size={12} />
                </button>
              )}

              {showSearchIcon && (
                <button
                  className="absolute right-3 top-1/2 z-10 grid h-6 w-6 -translate-y-1/2 place-items-center rounded-full text-deep-cove/25 hover:text-deep-cove/50"
                  onClick={handleSearch}
                >
                  <RightArc className="h-4 w-4" />
                </button>
              )}
            </div>
          </div>
          <div className="w-full h-full pt-16">
            <SearchPopup
              themes={themes}
              debounceSearch={debounceSearch.length}
              setOpenPopup={setOpenPopup}
            />
          </div>
        </SheetContent>
      </Sheet>
    )
  }

  return (
    <div className="relative">
      <div
        className={cn('relative z-10 rounded-xl', className)}
        style={{
          boxShadow: openPopup ? '0px 10px 40px 0px #00000033' : 'none',
        }}
      >
        <Input
          icon={<Search size={20} className="text-deep-cove/50" />}
          placeholder={t('search_placeholder')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onEnter={handleSearch}
          onMouseDown={() => setOpenPopup(true)}
          className={cn('relative z-10 overflow-hidden rounded-xl', {
            'rounded-b-none': openPopup,
          })}
          inputClassName={cn(
            'peer bg-deep-cove/5 border-none placeholder:text-deep-cove/50 caret-deep-cove hover:bg-search duration-300',
            { 'rounded-b-none bg-search': openPopup },
          )}
          hideLabel
        />

        {debounceSearch && !showSearchIcon && (
          <button
            className={cn(
              'absolute right-3 top-1/2 z-10 grid h-4 w-4 -translate-y-1/2 place-items-center rounded-full bg-deep-cove/25 text-white',
            )}
            onClick={handleClearSearch}
          >
            <X size={12} />
          </button>
        )}

        {showSearchIcon && (
          <button
            className="absolute right-3 top-1/2 z-10 grid h-6 w-6 -translate-y-1/2 place-items-center rounded-full text-deep-cove/25 hover:text-deep-cove/50"
            onClick={handleSearch}
          >
            <RightArc className="h-4 w-4" />
          </button>
        )}
        {openPopup && (
          <SearchPopup
            themes={themes}
            debounceSearch={debounceSearch}
            setOpenPopup={setOpenPopup}
          />
        )}
      </div>
      {openPopup && <div className="fixed inset-0" onClick={() => setOpenPopup(false)} />}
    </div>
  )
}

export default SearchInput
