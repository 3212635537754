const RightArrow = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none" className={className}>
      <g clipPath="url(#clip0_2725_2978)">
        <path
          d="M10.1602 18.1479C10.0234 18.0386 9.96875 17.8745 9.96875 17.6831C9.96875 17.519 10.0234 17.355 10.1602 17.2456L12.7578 14.6206H1.65625C1.27344 14.6206 1 14.3472 1 13.9644C1 13.6089 1.27344 13.3081 1.65625 13.3081H12.7578L10.1602 10.7104C9.88672 10.4644 9.88672 10.0542 10.1602 9.80811C10.4062 9.53467 10.8164 9.53467 11.0898 9.80811L14.8086 13.5269C15.0547 13.7729 15.0547 14.1831 14.8086 14.4292L11.0898 18.1479C10.8164 18.4214 10.4062 18.4214 10.1602 18.1479Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2725_2978">
          <rect width="16" height="28" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RightArrow
