import Loading from '@/components/Loading'
import { currentUserInfoQuery } from '@/pages/login-flow/graphql/query'
import useAuthStore, { UserRole, getUserRoles } from '@/stores/useAuthStore'
import { useQueryGraphQL } from '@/use-graphql'
import { useLocation, Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { routes } from './utils'
import { OrganizationsCompanyAccountTypeEnum } from '@/gql/graphql'

export const defaultUrl = (roles: UserRole[]) => {
  if (roles.includes('customer_support') || roles.includes('staff')) {
    return routes.customerDashboard.getPath()
  }

  if (roles.includes('company_employee')) {
    return '/'
  }

  if (roles.includes('company_admin')) {
    return routes.companyDashboard.getPath()
  }

  if (roles.includes('vendor_member')) {
    return routes.vendorDashboard.getPath()
  }

  return routes.profile.getPath()
}

// If user is company member, set org to custom dimension in matomo
const storeOrgInAnalytics = (companySlug?: string) => {
  if (!companySlug) {
    return
  }
  try {
    const customDimensionId = 1 // this is configured in matomo
    // @ts-ignore
    window._paq.push(['setCustomDimension', customDimensionId, companySlug])
  } catch (e) {
    return
  }
}

const storeUserInAnalytics = (userId?: string) => {
  if (!userId) {
    return
  }
  try {
    // @ts-ignore
    window._paq.push(['setUserId', userId])
  } catch (e) {
    return
  }
}

const RequireAuth = ({
  allowedRoles,
  requiredAccountType,
}: {
  allowedRoles?: UserRole[]
  requiredAccountType?: OrganizationsCompanyAccountTypeEnum
}) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const loginCode = searchParams.get('login_code')
  const { user, setUser, logout, accountType } = useAuthStore()
  const { data, isInitialLoading } = useQueryGraphQL(
    ['current-user'],
    currentUserInfoQuery,
    {},
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      onSuccess: (data) => {
        if (!data?.me) {
          logout()
        } else {
          // @ts-ignore
          setUser({ ...data?.me })
          storeUserInAnalytics(data?.me?.id)
          storeOrgInAnalytics(data?.me?.companyMember?.company.slug)
        }
      },
      onError: () => {
        logout()
      },
    },
  )

  if (isInitialLoading) {
    return <Loading />
  }

  if (data?.me || user) {
    // @ts-ignore
    const roles = getUserRoles(data?.me || user)

    if (requiredAccountType && accountType && accountType !== requiredAccountType) {
      return <Navigate to={defaultUrl(roles) + location.search} replace />
    }

    if (!allowedRoles) {
      return <Outlet />
    }

    return roles.find((role) => !allowedRoles?.length || allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : (
      <Navigate to={defaultUrl(roles) + location.search} state={{ from: location }} replace />
    )
  }

  return (
    <Navigate
      to={loginCode ? routes.login.getPath(loginCode) : routes.requestCode.getPath()}
      state={{ from: location, search: location.search }}
      replace
    />
  )
}

export default RequireAuth
