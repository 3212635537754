import { Input } from './ui/input'
import { FilterDropdown } from './FilterDropdown'
import phoneCountries from '@/constants/phoneCountries'
import { cn } from '@/utils/shadcn'
import '@/assets/flag.scss'
import { useEffect, useRef } from 'react'

export interface Props {
  value: string
  onChange: (value: string) => void
  dialCode: {
    code: string
    dialCode: string
  }
  onDialCodeChange: (value: { code: string; dialCode: string }) => void
  label?: string
  id?: string
  autoFocus?: boolean
  error?: string
  className?: string
}

const PhoneCountryInput = ({
  value,
  onChange,
  dialCode,
  onDialCodeChange,
  label,
  id,
  autoFocus,
  error,
  className,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  const handleChangeCode = (value?: string) => {
    onDialCodeChange({
      code: value?.split('__')[0] || '',
      dialCode: value?.split('__')[1] || '',
    })
  }

  return (
    <div className={cn('relative', className)}>
      <FilterDropdown
        popupClassName="sm:-ml-4 sm:mt-3"
        buttonClassName="absolute left-4 top-[31px] z-10 border-r border-grey-medium pr-2"
        contentClassName="sm:max-h-[25vh] pt-0"
        showSearch
        contentWidth={320}
        value={Object.values(dialCode).join('__')}
        onChange={handleChangeCode}
        options={phoneCountries.map((e) => ({
          value: `${e.code}__${e.dial_code}`,
          label: `${e.name} ${e.dial_code}`,
        }))}
        customButton={<div className={cn('flag h-5', dialCode.code.toLowerCase())}></div>}
        hideCancel
        sideOffset={-4}
      />
      <div className="dial-code-indicator absolute left-[84px] top-8 z-10">{dialCode.dialCode}</div>
      <Input
        style={{ paddingLeft: 84 + dialCode.dialCode.length * 10 }}
        label={label}
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="number"
        ref={inputRef}
        error={error}
        inputMode="tel"
      />
    </div>
  )
}

export default PhoneCountryInput
