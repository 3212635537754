import useModalStore, { Modal as ModalType } from '@/stores/useModalStore'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import { cn } from '@/utils/shadcn'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { Sheet, SheetContent } from '@/components/ui/sheet'
import { useState } from 'react'

const CardStyle = ({
  name,
  Component,
  header,
  close,
  callback,
  isAlert,
  maxWidth,
}: ModalType & { close: () => void }) => {
  const [open, setOpen] = useState(true)
  const { isMobile } = useWindowDimensions()
  const handleClose = (ignoreIsAlert?: boolean) => {
    if (!ignoreIsAlert && isAlert) {
      return
    }
    callback?.()
    close()
  }
  const handleOpenChange = (open) => {
    if (open) {
      setOpen(true)
      return
    }
    setOpen(false)
    handleClose(true)
  }

  if (isMobile) {
    return (
      <Sheet key={name} open={open} onOpenChange={handleOpenChange}>
        <SheetContent className="w-full rounded-t-xl p-0 [&>.close-button]:hidden" side="bottom">
          {header}
          <Component close={() => handleClose(true)} />
        </SheetContent>
      </Sheet>
    )
  }
  return (
    <Dialog key={name} open>
      <DialogContent
        onInteractOutside={() => handleClose()}
        onEscapeKeyDown={() => handleClose()}
        onClose={() => handleClose(true)}
        className="max-h-[80%] w-fit overflow-auto rounded-3xl p-0 sm:max-w-[90%] lg:max-w-5xl [&>.close-button]:hidden"
        style={{ maxWidth }}
      >
        {header && (
          <div className="mb-8 flex items-center justify-between">
            <DialogTitle>{header}</DialogTitle>
          </div>
        )}
        <Component close={() => handleClose(true)} />
      </DialogContent>
    </Dialog>
  )
}

const Modal = ({
  name,
  Component,
  header,
  close,
  isAlert,
  callback,
  isConfirmDialog,
  isFullPage,
  isCardStyle,
  maxWidth,
}: ModalType & { close: () => void }) => {
  const handleClose = (ignoreIsAlert?: boolean) => {
    if (!ignoreIsAlert && isAlert) {
      return
    }
    callback?.()
    close()
  }

  if (isConfirmDialog) {
    return <ConfirmDialog header={header} Component={Component} callback={callback} close={close} />
  }

  if (isCardStyle) {
    return (
      <CardStyle
        name={name}
        header={header}
        Component={Component}
        close={close}
        callback={callback}
        isAlert={isAlert}
        maxWidth={maxWidth}
      />
    )
  }

  return (
    <Dialog key={name} open>
      <DialogContent
        onInteractOutside={() => handleClose()}
        onEscapeKeyDown={() => handleClose()}
        onClose={() => handleClose(true)}
        className={cn('h-5/6 overflow-auto', {
          'h-screen w-screen max-w-none p-0 md:mt-[74px] [&+.close-button]:md:block [&>.close-button]:md:hidden md:pb-20':
            isFullPage,
        })}
        style={{ maxWidth }}
      >
        {header && (
          <div className="mb-8 flex items-center justify-between">
            <DialogTitle>{header}</DialogTitle>
          </div>
        )}
        <Component close={() => handleClose(true)} />
      </DialogContent>
    </Dialog>
  )
}

const ModalContainer = () => {
  const modals = useModalStore((state) => state.modals)
  const closeModal = useModalStore((state) => state.closeModal)
  const keys = Object.keys(modals)

  return keys.length === 0 ? null : (
    <div>
      {keys.map((modalName) => {
        return (
          <Modal
            {...modals[modalName]}
            close={() => {
              closeModal(modalName)
            }}
            key={modalName}
          />
        )
      })}
    </div>
  )
}

export default ModalContainer
