/* eslint-disable */
import * as types from './graphql'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query Locations {\n    locations {\n      id\n      name\n      category\n    }\n  }\n':
    types.LocationsDocument,
  '\n  mutation UpdateLanguage($language: UsersUserLanguageEnum!) {\n    updateLanguage(input: { language: $language }) {\n      ... on UserType {\n        language\n      }\n    }\n  }\n':
    types.UpdateLanguageDocument,
  '\n  query ThemesQuery {\n    themes {\n      id\n      name\n      slug\n      icon {\n        url\n      }\n      highlights {\n        highlightedItem {\n          ... on ProductType {\n            id\n            __typename\n            child {\n              ... on ItemType {\n                id\n                __typename\n              }\n              ... on ServiceType {\n                id\n                __typename\n              }\n            }\n          }\n          ... on VendorType {\n            id\n            __typename\n          }\n          ... on ContentPageType {\n            id\n            __typename\n            child {\n              ... on BlogType {\n                id\n                __typename\n              }\n              ... on GuideType {\n                id\n                __typename\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.ThemesQueryDocument,
  '\n  query TagPages {\n    tagPages {\n      id\n      icon {\n        url\n      }\n      tag {\n        slug\n        name\n      }\n    }\n  }\n':
    types.TagPagesDocument,
  '\n  mutation CreateFavoriteMutation($input: FavoriteInput!) {\n    createFavorite(input: $input)\n  }\n':
    types.CreateFavoriteMutationDocument,
  '\n  mutation DeleteFavoriteMutation($input: FavoriteInput!) {\n    deleteFavorite(input: $input)\n  }\n':
    types.DeleteFavoriteMutationDocument,
  '\n  query Favorites {\n    favorites {\n      itemId\n      type\n    }\n  }\n':
    types.FavoritesDocument,
  '\n  query Blog($slug: String!) {\n    blog(slug: $slug) {\n      id\n      title\n      slug\n      intro\n      body\n      author\n      mainImage(resize: { fill: { width: 1280, height: 600 } }) {\n        id\n        url\n      }\n      vendor {\n        name\n        slug\n        smallImage(resize: { fill: { width: 64, height: 64 } }) {\n          alt\n          height\n          id\n          url\n          width\n        }\n      }\n      images(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      themes {\n        __typename\n        id\n        name\n        slug\n        icon {\n          url\n        }\n      }\n      tags {\n        __typename\n        id\n        name\n        slug\n      }\n    }\n  }\n':
    types.BlogDocument,
  '\n  query Blogs($pagination: OffsetPaginationInput) {\n    blogs(pagination: $pagination) {\n      items {\n        id\n        title\n        slug\n        intro\n        mainImage(resize: { fill: { width: 1280, height: 600 } }) {\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n        images(resize: { max: { width: 800, height: 800 } }) {\n          url\n        }\n        child {\n          __typename\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.BlogsDocument,
  '\n  mutation CreateBooking($input: CreateBookingInput!) {\n    createBooking(input: $input) {\n      ... on BookingType {\n        status\n      }\n    }\n  }\n':
    types.CreateBookingDocument,
  '\n  mutation requestBudget($input: BudgetRequestInput!) {\n    budgetRequest(input: $input)\n  }\n':
    types.RequestBudgetDocument,
  '\n  mutation acceptPurchaseAgreement($input: AcceptTermsInput!) {\n    acceptPurchaseAgreement(input: $input) {\n      createdAt\n      version\n      content\n      accepted\n    }\n  }\n':
    types.AcceptPurchaseAgreementDocument,
  '\n  query PurchaseAgreement {\n    purchaseAgreement {\n      accepted\n      content\n      createdAt\n      version\n    }\n  }\n':
    types.PurchaseAgreementDocument,
  '\n  mutation CreateSegment($input: SegmentInput!) {\n    createSegment(input: $input) {\n      id\n    }\n  }\n':
    types.CreateSegmentDocument,
  '\n  mutation UpdateSegment($id: ID!, $input: SegmentInput!) {\n    updateSegment(id: $id, input: $input) {\n      id\n    }\n  }\n':
    types.UpdateSegmentDocument,
  '\n  mutation CreateCampaignMessageTemplate($input: CampaignMessageTemplateInput!) {\n    createCampaignMessageTemplate(input: $input) {\n      content\n      id\n      name\n    }\n  }\n':
    types.CreateCampaignMessageTemplateDocument,
  '\n  mutation UpdateCampaignMessageTemplate($id: ID!, $input: CampaignMessageTemplateInput!) {\n    updateCampaignMessageTemplate(id: $id, input: $input) {\n      content\n      id\n      name\n    }\n  }\n':
    types.UpdateCampaignMessageTemplateDocument,
  '\n  mutation CreateCampaign($input: CampaignInput!) {\n    createCampaign(input: $input) {\n      message\n      name\n      id\n      sentAt\n      segment {\n        name\n        filters\n        contacts {\n          totalItemsCount\n        }\n      }\n      scheduledAt\n    }\n  }\n':
    types.CreateCampaignDocument,
  '\n  mutation UpdateCampaign($id: ID!, $input: CampaignInput!) {\n    updateCampaign(id: $id, input: $input) {\n      message\n      name\n      id\n      sentAt\n      segment {\n        name\n        filters\n        contacts {\n          totalItemsCount\n        }\n      }\n      scheduledAt\n    }\n  }\n':
    types.UpdateCampaignDocument,
  '\n  mutation DeleteCampaign($id: ID!) {\n    deleteCampaign(id: $id)\n  }\n':
    types.DeleteCampaignDocument,
  '\n  query CampaignUsers($filters: UserFilter, $pagination: OffsetPaginationInput) {\n    campaignUsers(filters: $filters, pagination: $pagination) {\n      items {\n        firstName\n        lastName\n        email\n        phoneNumber\n        id\n        lastLogin\n        companyMember {\n          company {\n            name\n          }\n          budget\n        }\n        userThemes {\n          theme {\n            name\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.CampaignUsersDocument,
  '\n  query Segments($pagination: OffsetPaginationInput) {\n    segments(pagination: $pagination) {\n      items {\n        contacts {\n          totalItemsCount\n        }\n        name\n        filters\n        updatedAt\n        id\n      }\n      totalItemsCount\n    }\n  }\n':
    types.SegmentsDocument,
  '\n  query SegmentDetail($id: ID!, $pagination: OffsetPaginationInput, $search: String) {\n    segmentDetail(id: $id) {\n      contacts(pagination: $pagination, search: $search) {\n        totalItemsCount\n        items {\n          firstName\n          lastName\n          email\n          id\n          lastLogin\n          phoneNumber\n          companyMember {\n            company {\n              name\n            }\n            budget\n          }\n          userThemes {\n            theme {\n              name\n            }\n          }\n        }\n      }\n      name\n      filters\n      id\n      updatedAt\n    }\n  }\n':
    types.SegmentDetailDocument,
  '\n  query MessageTemplates($search: String, $pagination: OffsetPaginationInput) {\n    campaignMessageTemplates(search: $search, pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        name\n        content\n      }\n    }\n  }\n':
    types.MessageTemplatesDocument,
  '\n  query Campaigns($search: String, $pagination: OffsetPaginationInput) {\n    campaigns(search: $search, pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        message\n        name\n        sentAt\n        scheduledAt\n        segment {\n          contacts {\n            totalItemsCount\n          }\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.CampaignsDocument,
  '\n  mutation budgetCompanyApproval($input: BudgetCompanyApprovalInput!) {\n    budgetCompanyApproval(input: $input)\n  }\n':
    types.BudgetCompanyApprovalDocument,
  '\n  mutation CreateCompanyEmployee($input: CreateUserInput!, $budget: Float, $companyId: ID) {\n    createCompanyEmployee(input: $input, budget: $budget, companyId: $companyId)\n  }\n':
    types.CreateCompanyEmployeeDocument,
  '\n  mutation UpdateCompanyEmployee($input: CreateUserInput!, $companyId: ID) {\n    updateCompanyEmployee(input: $input, companyId: $companyId)\n  }\n':
    types.UpdateCompanyEmployeeDocument,
  '\n  mutation DeleteCompanyEmployee($id: ID!, $companyId: ID) {\n    deleteCompanyEmployee(id: $id, companyId: $companyId)\n  }\n':
    types.DeleteCompanyEmployeeDocument,
  '\n  query CompanyStatistics {\n    companyStatistics {\n      privateEnergy\n      seatsInUse\n      totalBookedServices\n      totalPendingBookings\n      totalSeats\n      workEnergy\n      totalUsersFinishedScan\n      totalFinishedSelfscan\n      totalFinishedCheckin\n      themes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      mostFavoriteThemes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.CompanyStatisticsDocument,
  '\n  query FilteredCompanyStatistics($slug: String, $dateRange: StatisticsDateRange = QUARTER) {\n    companyStatistics(slug: $slug, dateRange: $dateRange) {\n      filteredBookedServices\n    }\n  }\n':
    types.FilteredCompanyStatisticsDocument,
  '\n  query budgetRequests($pagination: OffsetPaginationInput, $search: String, $status: String) {\n    budgetRequests(pagination: $pagination, search: $search, status: $status) {\n      items {\n        id\n        amount\n        createdAt\n        updatedAt\n        approvedByCompany\n        message\n        product {\n          name\n          slug\n          price\n          child {\n            __typename\n            ... on ServiceType {\n              numberOfSessions\n            }\n            ... on ItemType {\n              id\n            }\n          }\n          vendor {\n            name\n            slug\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n        }\n        employee {\n          budget\n          user {\n            lastName\n            firstName\n          }\n        }\n      }\n      totalItemsCount\n      outstandingItemsCount\n    }\n  }\n':
    types.BudgetRequestsDocument,
  '\n  query CompanyMembers(\n    $slug: String\n    $search: String\n    $selfscan: String\n    $status: String\n    $pagination: OffsetPaginationInput\n  ) {\n    companyMembers(\n      slug: $slug\n      search: $search\n      selfscan: $selfscan\n      status: $status\n      pagination: $pagination\n    ) {\n      totalItemsCount\n      items {\n        id\n        inactive\n        user {\n          email\n          lastName\n          firstName\n          phoneNumber\n          lastLogin\n          userThemes {\n            id\n          }\n        }\n        budget\n      }\n    }\n  }\n':
    types.CompanyMembersDocument,
  '\n  query Company {\n    company {\n      totalSeats\n      seatsInUse\n    }\n  }\n':
    types.CompanyDocument,
  '\n  mutation MarkSupportedUser($userId: ID!) {\n    markSupportedUser(userId: $userId)\n  }\n':
    types.MarkSupportedUserDocument,
  '\n  mutation BulkUpdateEmployeeBudget($slug: String!, $budget: Float!) {\n    bulkUpdateEmployeeBudget(slug: $slug, budget: $budget)\n  }\n':
    types.BulkUpdateEmployeeBudgetDocument,
  '\n  mutation SynchronizeSendgrid {\n    synchronizeUsersToSendgrid\n    createSendgridSegments\n  }\n':
    types.SynchronizeSendgridDocument,
  '\n  query Companies($search: String, $pagination: OffsetPaginationInput) {\n    companies(search: $search, pagination: $pagination) {\n      items {\n        id\n        slug\n        name\n        seatsInUse\n        totalSeats\n      }\n      totalItemsCount\n    }\n  }\n':
    types.CompaniesDocument,
  '\n  query CompanyDetail($slug: String!) {\n    companyStatistics(slug: $slug) {\n      company {\n        id\n        name\n        budgetPerEmployee\n        registeredMembers\n      }\n      seatsInUse\n      totalSeats\n      totalBookedServices\n      totalPendingBookings\n      totalUsersFinishedScan\n      totalPendingBudget\n      totalSpendBudget\n      totalAvailableBudget\n      mostFavoriteThemes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      privateEnergy\n      themes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      totalFinishedCheckin\n      totalFinishedSelfscan\n      workEnergy\n    }\n  }\n':
    types.CompanyDetailDocument,
  '\n  query UserInfo($id: ID!) {\n    userInfo(id: $id) {\n      firstName\n      lastName\n      email\n      companyMember {\n        company {\n          name\n        }\n        workEnergy\n        privateEnergy\n      }\n      userThemes {\n        ...ScoredThemeFragment\n      }\n      nonThemeAnswers {\n        text\n        answer\n      }\n    }\n  }\n':
    types.UserInfoDocument,
  '\n  query NeedSupportUsers($pagination: OffsetPaginationInput) {\n    needSupportUsers(pagination: $pagination) {\n      items {\n        id\n        firstName\n        lastName\n        email\n        companyMember {\n          id\n          company {\n            name\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.NeedSupportUsersDocument,
  '\n  query OverviewStatistics {\n    overviewStatistics {\n      totalPendingBookings\n      bookedServices\n      filteredBookings\n      mostFavoriteThemes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      privateEnergy\n      registrations\n      themes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      totalFinishedCheckin\n      totalFinishedSelfscan\n      workEnergy\n      totalSeats\n      seatsInUse\n      totalPendingBudget\n      totalSpendBudget\n      totalAvailableBudget\n    }\n  }\n':
    types.OverviewStatisticsDocument,
  '\n  query FilteredBookingsOverview($dateRange: StatisticsDateRange = QUARTER) {\n    overviewStatistics(dateRange: $dateRange) {\n      filteredBookings\n    }\n  }\n':
    types.FilteredBookingsOverviewDocument,
  '\n  query VendorWithoutUserChats($pagination: OffsetPaginationInput) {\n    vendorWithoutUserChats(pagination: $pagination) {\n      totalItemsCount\n      items {\n        lastSent\n        messages(pagination: { offset: 0, limit: 1 }) {\n          items {\n            content\n          }\n        }\n        participant1 {\n          name\n          email\n          id\n        }\n        participant2 {\n          name\n          email\n          id\n        }\n      }\n    }\n  }\n':
    types.VendorWithoutUserChatsDocument,
  '\n  query NotFollowedChats($pagination: OffsetPaginationInput) {\n    notFollowedChats(pagination: $pagination) {\n      totalItemsCount\n      items {\n        lastSent\n        messages(pagination: { offset: 0, limit: 1 }) {\n          items {\n            content\n            sender {\n              pk\n            }\n          }\n        }\n        participant1 {\n          name\n          email\n          id\n        }\n        participant2 {\n          name\n          email\n          id\n        }\n      }\n    }\n  }\n':
    types.NotFollowedChatsDocument,
  '\n  query ExportScans($excludeIds: String, $pagination: OffsetPaginationInput) {\n    exportScans(excludeIds: $excludeIds, pagination: $pagination) {\n      items {\n        userId\n        organizationId\n        scanType\n        scoredQuestions {\n          id\n          name\n          score\n        }\n        scoredThemes {\n          id\n          name\n          score\n        }\n        date\n        bookedProducts\n      }\n      totalItemsCount\n    }\n  }\n':
    types.ExportScansDocument,
  '\n  mutation RegisterWebinar($id: ID!) {\n    registerWebinar(id: $id)\n  }\n':
    types.RegisterWebinarDocument,
  '\n  fragment ThemeFragment on ThemeType {\n    id\n    name\n    slug\n    icon {\n      url\n    }\n    color\n    description\n    highlights {\n      highlightedItem {\n        __typename\n        ...HighLightedContentPageFragment\n        ...VendorFragment\n        ...ProductFragment\n      }\n    }\n  }\n':
    types.ThemeFragmentFragmentDoc,
  '\n  fragment UserThemeFragment on UserThemeType {\n    id\n    theme {\n      ...ThemeFragment\n    }\n    isFavorite\n    score\n  }\n':
    types.UserThemeFragmentFragmentDoc,
  '\n  query Dashboard {\n    dashboard {\n      showScan\n      userThemes {\n        ...UserThemeFragment\n      }\n      themes {\n        ...ThemeFragment\n      }\n      contentPages {\n        ...ContentPageFragment\n      }\n    }\n  }\n':
    types.DashboardDocument,
  '\n  query FaqQuery {\n    faqs {\n      id\n      question\n      answer\n    }\n  }\n':
    types.FaqQueryDocument,
  '\n  query Webinars($pagination: OffsetPaginationInput) {\n    activeWebinars(pagination: $pagination) {\n      items {\n        id\n        name\n        registered\n        description\n        startAt\n        endAt\n        externalLink\n        image(resize: { fill: { width: 400, height: 300 } }) {\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.WebinarsDocument,
  '\n  mutation AdminBookingApproval($input: ApproveBookingInput!) {\n    adminBookingApproval(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n':
    types.AdminBookingApprovalDocument,
  '\n  mutation UpdateBillingStatus(\n    $input: UpdateBillingStatusInput = { companyBillingStatus: PENDING }\n  ) {\n    updateBillingStatus(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n':
    types.UpdateBillingStatusDocument,
  '\n  mutation VendorBookingApproval($input: ApproveBookingInput!) {\n    vendorBookingApproval(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n':
    types.VendorBookingApprovalDocument,
  '\n  mutation SuggestBookingDate($input: SuggestDateInput!) {\n    suggestBookingDate(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n':
    types.SuggestBookingDateDocument,
  '\n  query VendorStatistics {\n    vendorStatistics {\n      bookedServices\n      receiveBudget\n    }\n  }\n':
    types.VendorStatisticsDocument,
  '\n  query Bookings($pagination: OffsetPaginationInput, $search: String, $filters: BookingFilter) {\n    bookings(pagination: $pagination, search: $search, filters: $filters) {\n      items {\n        id\n        status\n        date\n        price\n        createdAt\n        vendorBillingStatus\n        companyBillingStatus\n        employee {\n          id\n          user {\n            firstName\n            lastName\n            email\n            phoneNumber\n          }\n          company {\n            name\n          }\n        }\n        employeeComment\n        product {\n          name\n          slug\n          price\n          child {\n            __typename\n            ... on ServiceType {\n              numberOfSessions\n            }\n            ... on ItemType {\n              id\n            }\n          }\n          vendor {\n            name\n            slug\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n        }\n        actions {\n          date\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.BookingsDocument,
  '\n  query SharedUserInfoList($pagination: OffsetPaginationInput, $search: String) {\n    sharedUserInfoList(pagination: $pagination, search: $search) {\n      items {\n        slug\n        user {\n          id\n          firstName\n          lastName\n          email\n          companyMember {\n            company {\n              name\n            }\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.SharedUserInfoListDocument,
  '\n  query FavoriteProducts($pagination: OffsetPaginationInput = {}) {\n    favoriteProducts(pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        name\n        slug\n        description\n        usePlaceholderImage\n        price\n        priceAlterText\n        template\n        image(resize: { max: { width: 1000, height: 1000 } }) {\n          url\n        }\n        images {\n          image(resize: { max: { width: 1000, height: 1000 } }) {\n            url\n          }\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n        child {\n          __typename\n          ... on ItemType {\n            id\n          }\n          ... on ServiceType {\n            id\n            numberOfSessions\n          }\n        }\n      }\n    }\n  }\n':
    types.FavoriteProductsDocument,
  '\n  query FavoriteVendors($pagination: OffsetPaginationInput = {}) {\n    favoriteVendors(pagination: $pagination) {\n      totalItemsCount\n      items {\n        name\n        slug\n        id\n        description\n        image(resize: { fill: { width: 480, height: 480 } }) {\n          url\n        }\n      }\n    }\n  }\n':
    types.FavoriteVendorsDocument,
  '\n  query FavoriteContentPages($pagination: OffsetPaginationInput = {}) {\n    favoriteContentPages(pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        title\n        slug\n        intro\n        mainImage(resize: { fill: { width: 480, height: 480 } }) {\n          id\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            alt\n            height\n            id\n            url\n            width\n          }\n        }\n        child {\n          __typename\n          ... on BlogType {\n            id\n          }\n          ... on GuideType {\n            id\n          }\n        }\n      }\n    }\n  }\n':
    types.FavoriteContentPagesDocument,
  '\n  query Guide($slug: String!) {\n    guide(slug: $slug) {\n      id\n      title\n      slug\n      intro\n      body\n      author\n      chapters {\n        header\n        body\n      }\n      mainImage(resize: { fill: { width: 480, height: 480 } }) {\n        id\n        url\n      }\n      vendor {\n        id\n        name\n        slug\n        smallImage(resize: { fill: { width: 64, height: 64 } }) {\n          alt\n          height\n          id\n          url\n          width\n        }\n      }\n      images(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      footerIcon {\n        url\n      }\n      footerHeader\n      footerText\n      themes {\n        id\n        name\n        icon {\n          url\n        }\n      }\n      tags {\n        id\n        name\n      }\n    }\n  }\n':
    types.GuideDocument,
  '\n  query Guides($pagination: OffsetPaginationInput) {\n    guides(pagination: $pagination) {\n      items {\n        id\n        title\n        slug\n        intro\n        mainImage(resize: { fill: { width: 1280, height: 600 } }) {\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n        images(resize: { max: { width: 800, height: 800 } }) {\n          url\n        }\n        child {\n          __typename\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.GuidesDocument,
  '\n  mutation requestEmailLoginCode($email: Email!) {\n    requestEmailLoginCode(input: { email: $email })\n  }\n':
    types.RequestEmailLoginCodeDocument,
  '\n  mutation requestTextLoginCode($emailCode: String!, $changePhoneNumber: PhoneNumber) {\n    requestTextLoginCode(input: { emailCode: $emailCode, changePhoneNumber: $changePhoneNumber })\n  }\n':
    types.RequestTextLoginCodeDocument,
  '\n  mutation login($textCode: String!, $emailCode: String!) {\n    login(input: { textCode: $textCode, emailCode: $emailCode }) {\n      id\n      email\n      firstName\n      lastName\n      phoneNumber\n      familyStatus\n      gender\n      dateOfBirth\n      language\n      companyMember {\n        company {\n          name\n          slug\n          budgetPerEmployee\n        }\n        isOwner\n        isAdmin\n        isEmployee\n        budget\n        totalBudget\n        workEnergy\n        privateEnergy\n      }\n      vendorMember {\n        vendor {\n          name\n          slug\n          smallImage(resize: { fill: { width: 80, height: 80 } }) {\n            url\n          }\n        }\n      }\n      userThemes {\n        id\n      }\n    }\n  }\n':
    types.LoginDocument,
  '\n  mutation logout {\n    logout\n  }\n': types.LogoutDocument,
  '\n  mutation acceptTerms($input: AcceptTermsInput!) {\n    acceptTerms(input: $input) {\n      createdAt\n      version\n      content\n      accepted\n    }\n  }\n':
    types.AcceptTermsDocument,
  '\n  mutation FillRegisterInfo($code: String!, $input: UserInput!) {\n    fillInRegisterInfo(code: $code, input: $input) {\n      ... on UserType {\n        email\n        phoneNumber\n      }\n    }\n  }\n':
    types.FillRegisterInfoDocument,
  '\n  query emailLoginInfo($emailCode: String!) {\n    emailLoginInfo(input: { emailCode: $emailCode }) {\n      user {\n        firstName\n        lastName\n        email\n        phoneNumber\n      }\n      organization {\n        ... on CompanyType {\n          name\n        }\n        ... on VendorType {\n          name\n        }\n      }\n    }\n  }\n':
    types.EmailLoginInfoDocument,
  '\n  query me {\n    me {\n      id\n      email\n      firstName\n      lastName\n      phoneNumber\n      familyStatus\n      gender\n      dateOfBirth\n      language\n      isSuperuser\n      isStaff\n      isCustomerSupport\n      lastLogin\n      companyMember {\n        company {\n          logo(resize: { fill: { width: 80, height: 80 } }) {\n            url\n          }\n          name\n          slug\n          budgetPerEmployee\n          companyBudget\n          accountType\n        }\n        isOwner\n        isAdmin\n        isEmployee\n        budget\n        totalBudget\n        workEnergy\n        privateEnergy\n      }\n      vendorMember {\n        vendor {\n          name\n          slug\n          smallImage(resize: { fill: { width: 80, height: 80 } }) {\n            url\n          }\n        }\n      }\n      userThemes {\n        id\n        isFavorite\n        theme {\n          name\n          slug\n          icon(resize: { fill: { width: 32, height: 32 } }) {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.MeDocument,
  '\n  query TermsQuery {\n    terms {\n      accepted\n      content\n      createdAt\n      version\n    }\n  }\n':
    types.TermsQueryDocument,
  '\n  mutation SendMessage($input: SendMessageInput!) {\n    sendMessage(input: $input) {\n      id\n    }\n  }\n':
    types.SendMessageDocument,
  '\n  mutation ReadChat($chatId: ID!) {\n    readChat(input: { chatId: $chatId }) {\n      id\n    }\n  }\n':
    types.ReadChatDocument,
  '\n  mutation CreateChat($input: CreateChatInput!) {\n    createChat(input: $input) {\n      id\n      participant2 {\n        name\n      }\n      participant1 {\n        isCurrentUser\n      }\n    }\n  }\n':
    types.CreateChatDocument,
  '\n  mutation BookingDateApproval($id: Int!) {\n    bookingDateApproval(input: { id: $id, approved: true }) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n':
    types.BookingDateApprovalDocument,
  '\n  mutation SendEmailForMessageByAdmin($id: ID!) {\n    sendEmailForMessageByAdmin(id: $id)\n  }\n':
    types.SendEmailForMessageByAdminDocument,
  '\n  mutation SendEmailForMessage($id: ID!) {\n    sendEmailForMessage(id: $id)\n  }\n':
    types.SendEmailForMessageDocument,
  '\n  mutation UpdateMessage($id: ID!, $input: UpdateMessageInput!) {\n    updateMessage(id: $id, input: $input) {\n      id\n    }\n  }\n':
    types.UpdateMessageDocument,
  '\n  mutation UpdateChat($id: ID!, $input: UpdateChatInput!) {\n    updateChat(id: $id, input: $input) {\n      id\n    }\n  }\n':
    types.UpdateChatDocument,
  '\n  query ChatContacts($pagination: OffsetPaginationInput, $filters: ChatFilter) {\n    chats(pagination: $pagination, filters: $filters) {\n      items {\n        id\n        participant1 {\n          id\n          name\n          isCurrentUser\n        }\n        participant2 {\n          id\n          name\n        }\n        hasUnreadMessage\n        messages(pagination: { limit: 1 }) {\n          items {\n            content\n            readAt\n            createdAt\n            type\n          }\n        }\n        flagged\n        archived\n      }\n      totalItemsCount\n    }\n  }\n':
    types.ChatContactsDocument,
  '\n  query Messages($chatId: ID!, $pagination: OffsetPaginationInput, $filters: MessageFilter, $search: String) {\n    chat(pk: $chatId) {\n      messages(pagination: $pagination, filters: $filters, search: $search) {\n        items {\n          id\n          direction\n          content\n          createdAt\n          type\n          sentEmailAt\n          readAt\n          archived\n          markedUnread\n          flagged\n          senderEmail\n        }\n        totalItemsCount\n      }\n    }\n  }\n':
    types.MessagesDocument,
  '\n  query HasUnreadChat {\n    hasUnreadChat {\n      normal\n      fromCustomerSupport\n    }\n  }\n':
    types.HasUnreadChatDocument,
  '\n  query Booking($id: ID!) {\n    booking(pk: $id) {\n      status\n    }\n  }\n':
    types.BookingDocument,
  '\n  query ChatTemplate {\n    chatTemplates {\n      content\n      id\n    }\n  }\n':
    types.ChatTemplateDocument,
  '\n  query service($slug: String!) {\n    service(slug: $slug) {\n      id\n      name\n      slug\n      description\n      richDescription\n      vendor {\n        ...VendorFragment\n      }\n      template\n      image(resize: { max: { width: 1000, height: 1000 } }) {\n        url\n      }\n      images {\n        image(resize: { max: { width: 1000, height: 1000 } }) {\n          url\n        }\n      }\n      descriptionImages(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      numberOfSessions\n      locationGroup {\n        name\n        locations {\n          id\n          name\n        }\n      }\n      price\n      template\n      usePlaceholderImage\n      priceAlterText\n      languages {\n        code\n      }\n      themes {\n        id\n      }\n      tags {\n        id\n      }\n      __typename\n    }\n  }\n':
    types.ServiceDocument,
  '\n  fragment HighLightedContentPageFragment on ContentPageType {\n    id\n    title\n    slug\n    intro\n    mainImage(resize: { fill: { width: 480, height: 480 } }) {\n      id\n      url\n    }\n    child {\n      __typename\n      ... on BlogType {\n        id\n      }\n      ... on GuideType {\n        id\n      }\n    }\n  }\n':
    types.HighLightedContentPageFragmentFragmentDoc,
  '\n  fragment VendorFragment on VendorType {\n    id\n    name\n    slug\n    description\n    city\n    image(resize: { fill: { width: 480, height: 480 } }) {\n      url\n    }\n    smallImage(resize: { fill: { width: 64, height: 64 } }) {\n      url\n    }\n    tags {\n      name\n    }\n    themes {\n      name\n    }\n    locationGroup {\n      name\n    }\n  }\n':
    types.VendorFragmentFragmentDoc,
  '\n  fragment ProductFragment on ProductType {\n    id\n    name\n    slug\n    description\n    price\n    image(resize: { fill: { width: 480, height: 480 } }) {\n      url\n    }\n    usePlaceholderImage\n    vendor {\n      ...VendorFragment\n    }\n    child {\n      __typename\n      ... on ItemType {\n        id\n      }\n      ... on ServiceType {\n        id\n        numberOfSessions\n      }\n    }\n  }\n':
    types.ProductFragmentFragmentDoc,
  '\n  fragment ContentPageFragment on ContentPageType {\n    id\n    title\n    slug\n    intro\n    mainImage(resize: { fill: { width: 480, height: 480 } }) {\n      id\n      url\n    }\n    vendor {\n      name\n      smallImage(resize: { fill: { width: 64, height: 64 } }) {\n        alt\n        height\n        id\n        url\n        width\n      }\n    }\n    child {\n      __typename\n      ... on BlogType {\n        id\n        slug\n      }\n      ... on GuideType {\n        id\n        slug\n      }\n    }\n  }\n':
    types.ContentPageFragmentFragmentDoc,
  '\n  query pdpData($slug: String!) {\n    productDetailPage(slug: $slug) {\n      name\n      slug\n      score\n      icon {\n        url\n      }\n      descriptionHeader\n      description\n      tags {\n        id\n        name\n        slug\n      }\n      highlights {\n        highlightedItem {\n          ... on ProductType {\n            id\n            __typename\n            child {\n              ... on ItemType {\n                id\n                __typename\n              }\n              ... on ServiceType {\n                id\n                __typename\n              }\n            }\n          }\n          ... on VendorType {\n            id\n            __typename\n          }\n          ... on ContentPageType {\n            id\n            __typename\n            child {\n              ... on BlogType {\n                id\n                __typename\n              }\n              ... on GuideType {\n                id\n                __typename\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PdpDataDocument,
  '\n  query products($slug: String!, $filter: ProductFilter, $pagination: OffsetPaginationInput) {\n    productDetailPage(slug: $slug) {\n      products(filters: $filter, pagination: $pagination) {\n        totalItemsCount\n        items {\n          id\n          name\n          slug\n          description\n          price\n          priceAlterText\n          template\n          usePlaceholderImage\n          image(resize: { max: { width: 1000, height: 1000 } }) {\n            url\n          }\n          images {\n            image(resize: { max: { width: 1000, height: 1000 } }) {\n              url\n            }\n          }\n          vendor {\n            name\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n          child {\n            __typename\n            ... on ItemType {\n              id\n            }\n            ... on ServiceType {\n              id\n              numberOfSessions\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.ProductsDocument,
  '\n  query vendors($slug: String!, $pagination: OffsetPaginationInput) {\n    productDetailPage(slug: $slug) {\n      vendors(pagination: $pagination) {\n        totalItemsCount\n        items {\n          name\n          slug\n          id\n          description\n          image(resize: { fill: { width: 480, height: 480 } }) {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.VendorsDocument,
  '\n  query contentPages($slug: String!, $pagination: OffsetPaginationInput) {\n    productDetailPage(slug: $slug) {\n      contentPages(pagination: $pagination) {\n        totalItemsCount\n        items {\n          ...ContentPageFragment\n        }\n      }\n    }\n  }\n':
    types.ContentPagesDocument,
  '\n  query tags($slug: String!) {\n    productDetailPage(slug: $slug) {\n      productTags {\n        id\n        name\n      }\n    }\n  }\n':
    types.TagsDocument,
  '\n  query vendor($slug: String!) {\n    vendor(slug: $slug) {\n      id\n      name\n      description\n      description2\n      description3\n      city\n      locationGroup {\n        name\n      }\n      website\n      languages {\n        code\n      }\n      studies {\n        name\n      }\n      image(resize: { fill: { width: 480, height: 480 } }) {\n        url\n      }\n      smallImage(resize: { fill: { width: 64, height: 64 } }) {\n        url\n      }\n      tags {\n        id\n        slug\n        name\n      }\n      themes {\n        id\n        slug\n        name\n      }\n    }\n  }\n':
    types.VendorDocument,
  '\n  query VendorProducts($vendorId: ID, $pagination: OffsetPaginationInput) {\n    products(\n      excludeWebinar: true\n      filters: { vendor: { id: $vendorId } }\n      pagination: $pagination\n    ) {\n      items {\n        id\n        name\n        slug\n        description\n        price\n        template\n        priceAlterText\n        usePlaceholderImage\n        image(resize: { fill: { width: 480, height: 480 } }) {\n          url\n        }\n        images {\n          image(resize: { max: { width: 1000, height: 1000 } }) {\n            url\n          }\n        }\n        child {\n          __typename\n          ... on ItemType {\n            id\n          }\n          ... on ServiceType {\n            id\n            numberOfSessions\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.VendorProductsDocument,
  '\n  query ProductItem($slug: String!) {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      description\n      richDescription\n      vendor {\n        ...VendorFragment\n      }\n      template\n      image(resize: { max: { width: 1000, height: 1000 } }) {\n        url\n      }\n      images {\n        image(resize: { max: { width: 1000, height: 1000 } }) {\n          url\n        }\n      }\n      descriptionImages(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      price\n      priceAlterText\n      usePlaceholderImage\n      template\n      themes {\n        id\n      }\n      tags {\n        id\n      }\n    }\n  }\n':
    types.ProductItemDocument,
  '\n  mutation UpdateInfoMutation($input: UserInput!) {\n    updateProfile(input: $input) {\n      ... on UserType {\n        email\n        phoneNumber\n      }\n    }\n  }\n':
    types.UpdateInfoMutationDocument,
  '\n  mutation UpdateFavoriteTheme($input: UpdateThemeInput!) {\n    updateFavoriteTheme(input: $input)\n  }\n':
    types.UpdateFavoriteThemeDocument,
  '\n  fragment ScoredThemeFragment on UserThemeType {\n    id\n    theme {\n      id\n      name\n      slug\n      order\n      description\n      color\n      icon {\n        url\n      }\n    }\n    isFavorite\n    score\n    scanType\n    answers {\n      answer\n      text\n    }\n  }\n':
    types.ScoredThemeFragmentFragmentDoc,
  '\n  query UserThemes {\n    me {\n      userThemes {\n        ...ScoredThemeFragment\n      }\n      nonThemeAnswers {\n        text\n        answer\n      }\n    }\n  }\n':
    types.UserThemesDocument,
  '\n  query TimeLine($pagination: OffsetPaginationInput, $lte: Date) {\n    timeline(pagination: $pagination, filters: { date: { lte: $lte } }) {\n      totalItemsCount\n      items {\n        id\n        date\n        image(resize: { fill: { width: 100, height: 100 } }) {\n          url\n        }\n        itemType\n        title\n      }\n    }\n  }\n':
    types.TimeLineDocument,
  '\n  mutation StartScan($input: StartScanInput!) {\n    startScan(input: $input)\n  }\n':
    types.StartScanDocument,
  '\n  mutation FinishScan($input: FinishScanInput!) {\n    finishScan(input: $input) {\n      id\n      needSupport\n    }\n  }\n':
    types.FinishScanDocument,
  '\n  mutation AnswerBinaryQuestion($input: BinaryAnswerInput!) {\n    answerBinaryQuestion(input: $input)\n  }\n':
    types.AnswerBinaryQuestionDocument,
  '\n  mutation AnswerMultipleChoiceQuestion($input: MultipleChoiceAnswerInput!) {\n    answerMultipleChoiceQuestion(input: $input)\n  }\n':
    types.AnswerMultipleChoiceQuestionDocument,
  '\n  mutation AnswerScoreQuestion($input: ScoreAnswerInput!) {\n    answerScoreQuestion(input: $input)\n  }\n':
    types.AnswerScoreQuestionDocument,
  '\n  mutation AnswerSliderQuestion($input: SliderAnswerInput!) {\n    answerSliderQuestion(input: $input)\n  }\n':
    types.AnswerSliderQuestionDocument,
  '\n  mutation AnswerTextQuestion($input: TextAnswerInput!) {\n    answerTextQuestion(input: $input)\n  }\n':
    types.AnswerTextQuestionDocument,
  '\n  query SelfScan {\n    selfScan {\n      id\n      introHeader\n      introText\n      outroHeader\n      outroText\n      questions(pagination: { limit: 100 }) {\n        items {\n          id\n          description\n          title\n          type\n          image(resize: { fill: { width: 600, height: 800 } }) {\n            url\n          }\n          child {\n            ... on ScoreQuestionType {\n              id\n              maxScore\n            }\n            ... on MultipleChoiceQuestionType {\n              id\n              choices {\n                weight\n                text\n              }\n            }\n            ... on BinaryQuestionType {\n              id\n            }\n            ... on SliderQuestionType {\n              id\n              maxScore\n            }\n            ... on TextQuestionType {\n              id\n            }\n          }\n        }\n        totalItemsCount\n      }\n    }\n  }\n':
    types.SelfScanDocument,
  '\n  query CheckIn {\n    checkIn {\n      id\n      introHeader\n      introText\n      outroHeader\n      outroText\n      questions(pagination: { limit: 100 }) {\n        items {\n          id\n          description\n          title\n          type\n          image(resize: { fill: { width: 600, height: 800 } }) {\n            url\n          }\n          child {\n            ... on ScoreQuestionType {\n              id\n              maxScore\n            }\n            ... on MultipleChoiceQuestionType {\n              id\n              choices {\n                weight\n                text\n              }\n            }\n            ... on BinaryQuestionType {\n              id\n            }\n            ... on SliderQuestionType {\n              id\n              maxScore\n            }\n            ... on TextQuestionType {\n              id\n            }\n          }\n        }\n        totalItemsCount\n      }\n    }\n  }\n':
    types.CheckInDocument,
  '\n  query SearchQuery(\n    $pagination: OffsetPaginationInput\n    $search: String!\n    $filters: SearchItemFilter = {}\n    $ignoreMixContent: Boolean = false\n    $prioritizedOrder: [String!]\n    $isTeamOffer: Boolean = false\n  ) {\n    search(\n      search: $search\n      pagination: $pagination\n      filters: $filters\n      ignoreMixContent: $ignoreMixContent\n      prioritizedOrder: $prioritizedOrder\n      isTeamOffer: $isTeamOffer\n    ) {\n      items {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.SearchQueryDocument,
  '\n  query MiniSearchQuery($search: String!) {\n    miniSearch(search: $search) {\n      blogs {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n      products {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n      experts {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.MiniSearchQueryDocument,
  '\n  query TagQuery {\n    tags {\n      name\n      slug\n      id\n    }\n  }\n':
    types.TagQueryDocument,
  '\n  query SearchHistoryQuery($search: String!) {\n    searchHistory(search: $search) {\n      id\n      search\n      updatedAt\n    }\n  }\n':
    types.SearchHistoryQueryDocument,
  '\n  mutation ApproveRequest($slug: String!) {\n    shareInfoApproval(input: { slug: $slug, status: APPROVED })\n  }\n':
    types.ApproveRequestDocument,
  '\n  mutation RejectRequest($slug: String!) {\n    shareInfoApproval(input: { slug: $slug, status: REFUSED })\n  }\n':
    types.RejectRequestDocument,
  '\n  query ShareUserInfo($slug: String!) {\n    sharedUserInfo(slug: $slug) {\n      message\n      status\n      vendors {\n        name\n      }\n      user {\n        firstName\n        lastName\n        email\n        companyMember {\n          company {\n            name\n          }\n          workEnergy\n          privateEnergy\n        }\n        userThemes {\n          ...ScoredThemeFragment\n        }\n        nonThemeAnswers {\n          text\n          answer\n        }\n      }\n    }\n  }\n':
    types.ShareUserInfoDocument,
  '\n  query TagPage($slug: String!) {\n    tagPage(slug: $slug) {\n      tag {\n        name\n        slug\n      }\n      icon {\n        url\n      }\n      descriptionHeader\n      description\n      highlights {\n        highlightedItem {\n          __typename\n          ...HighLightedContentPageFragment\n          ...VendorFragment\n          ...ProductFragment\n        }\n      }\n    }\n  }\n':
    types.TagPageDocument,
  '\n  query tagProducts($slug: String!, $filter: ProductFilter, $pagination: OffsetPaginationInput) {\n    tagPage(slug: $slug) {\n      products(filters: $filter, pagination: $pagination) {\n        totalItemsCount\n        items {\n          id\n          name\n          slug\n          description\n          price\n          priceAlterText\n          template\n          usePlaceholderImage\n          image(resize: { fill: { width: 480, height: 480 } }) {\n            url\n          }\n          images {\n            image(resize: { max: { width: 1000, height: 1000 } }) {\n              url\n            }\n          }\n          vendor {\n            name\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n          child {\n            __typename\n            ... on ItemType {\n              id\n            }\n            ... on ServiceType {\n              id\n              numberOfSessions\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.TagProductsDocument,
  '\n  query tagVendors($slug: String!, $pagination: OffsetPaginationInput) {\n    tagPage(slug: $slug) {\n      vendors(pagination: $pagination) {\n        totalItemsCount\n        items {\n          name\n          slug\n          id\n          description\n          image(resize: { fill: { width: 480, height: 480 } }) {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.TagVendorsDocument,
  '\n  query tagContentPages($slug: String!, $pagination: OffsetPaginationInput) {\n    tagPage(slug: $slug) {\n      contentPages(pagination: $pagination) {\n        totalItemsCount\n        items {\n          ...ContentPageFragment\n        }\n      }\n    }\n  }\n':
    types.TagContentPagesDocument,
  '\n  query VendorsOverview($pagination: OffsetPaginationInput, $filters: VendorListFilter ) {\n    vendors(pagination: $pagination, filters: $filters) {\n      items {\n        id\n        name\n        slug\n        description\n        image {\n          url\n        }\n      }\n      totalItemsCount\n    }\n  }\n':
    types.VendorsOverviewDocument,
  '\n  query AllTagsQuery {\n    tags {\n      name\n      id\n      slug\n    }\n  }\n':
    types.AllTagsQueryDocument,
  '\n  query AllThemesQuery {\n    themes {\n      id\n      name\n      slug\n    }\n  }\n':
    types.AllThemesQueryDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Locations {\n    locations {\n      id\n      name\n      category\n    }\n  }\n',
): typeof import('./graphql').LocationsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateLanguage($language: UsersUserLanguageEnum!) {\n    updateLanguage(input: { language: $language }) {\n      ... on UserType {\n        language\n      }\n    }\n  }\n',
): typeof import('./graphql').UpdateLanguageDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ThemesQuery {\n    themes {\n      id\n      name\n      slug\n      icon {\n        url\n      }\n      highlights {\n        highlightedItem {\n          ... on ProductType {\n            id\n            __typename\n            child {\n              ... on ItemType {\n                id\n                __typename\n              }\n              ... on ServiceType {\n                id\n                __typename\n              }\n            }\n          }\n          ... on VendorType {\n            id\n            __typename\n          }\n          ... on ContentPageType {\n            id\n            __typename\n            child {\n              ... on BlogType {\n                id\n                __typename\n              }\n              ... on GuideType {\n                id\n                __typename\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').ThemesQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TagPages {\n    tagPages {\n      id\n      icon {\n        url\n      }\n      tag {\n        slug\n        name\n      }\n    }\n  }\n',
): typeof import('./graphql').TagPagesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateFavoriteMutation($input: FavoriteInput!) {\n    createFavorite(input: $input)\n  }\n',
): typeof import('./graphql').CreateFavoriteMutationDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteFavoriteMutation($input: FavoriteInput!) {\n    deleteFavorite(input: $input)\n  }\n',
): typeof import('./graphql').DeleteFavoriteMutationDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Favorites {\n    favorites {\n      itemId\n      type\n    }\n  }\n',
): typeof import('./graphql').FavoritesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Blog($slug: String!) {\n    blog(slug: $slug) {\n      id\n      title\n      slug\n      intro\n      body\n      author\n      mainImage(resize: { fill: { width: 1280, height: 600 } }) {\n        id\n        url\n      }\n      vendor {\n        name\n        slug\n        smallImage(resize: { fill: { width: 64, height: 64 } }) {\n          alt\n          height\n          id\n          url\n          width\n        }\n      }\n      images(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      themes {\n        __typename\n        id\n        name\n        slug\n        icon {\n          url\n        }\n      }\n      tags {\n        __typename\n        id\n        name\n        slug\n      }\n    }\n  }\n',
): typeof import('./graphql').BlogDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Blogs($pagination: OffsetPaginationInput) {\n    blogs(pagination: $pagination) {\n      items {\n        id\n        title\n        slug\n        intro\n        mainImage(resize: { fill: { width: 1280, height: 600 } }) {\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n        images(resize: { max: { width: 800, height: 800 } }) {\n          url\n        }\n        child {\n          __typename\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').BlogsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateBooking($input: CreateBookingInput!) {\n    createBooking(input: $input) {\n      ... on BookingType {\n        status\n      }\n    }\n  }\n',
): typeof import('./graphql').CreateBookingDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation requestBudget($input: BudgetRequestInput!) {\n    budgetRequest(input: $input)\n  }\n',
): typeof import('./graphql').RequestBudgetDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation acceptPurchaseAgreement($input: AcceptTermsInput!) {\n    acceptPurchaseAgreement(input: $input) {\n      createdAt\n      version\n      content\n      accepted\n    }\n  }\n',
): typeof import('./graphql').AcceptPurchaseAgreementDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PurchaseAgreement {\n    purchaseAgreement {\n      accepted\n      content\n      createdAt\n      version\n    }\n  }\n',
): typeof import('./graphql').PurchaseAgreementDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateSegment($input: SegmentInput!) {\n    createSegment(input: $input) {\n      id\n    }\n  }\n',
): typeof import('./graphql').CreateSegmentDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateSegment($id: ID!, $input: SegmentInput!) {\n    updateSegment(id: $id, input: $input) {\n      id\n    }\n  }\n',
): typeof import('./graphql').UpdateSegmentDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateCampaignMessageTemplate($input: CampaignMessageTemplateInput!) {\n    createCampaignMessageTemplate(input: $input) {\n      content\n      id\n      name\n    }\n  }\n',
): typeof import('./graphql').CreateCampaignMessageTemplateDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateCampaignMessageTemplate($id: ID!, $input: CampaignMessageTemplateInput!) {\n    updateCampaignMessageTemplate(id: $id, input: $input) {\n      content\n      id\n      name\n    }\n  }\n',
): typeof import('./graphql').UpdateCampaignMessageTemplateDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateCampaign($input: CampaignInput!) {\n    createCampaign(input: $input) {\n      message\n      name\n      id\n      sentAt\n      segment {\n        name\n        filters\n        contacts {\n          totalItemsCount\n        }\n      }\n      scheduledAt\n    }\n  }\n',
): typeof import('./graphql').CreateCampaignDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateCampaign($id: ID!, $input: CampaignInput!) {\n    updateCampaign(id: $id, input: $input) {\n      message\n      name\n      id\n      sentAt\n      segment {\n        name\n        filters\n        contacts {\n          totalItemsCount\n        }\n      }\n      scheduledAt\n    }\n  }\n',
): typeof import('./graphql').UpdateCampaignDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteCampaign($id: ID!) {\n    deleteCampaign(id: $id)\n  }\n',
): typeof import('./graphql').DeleteCampaignDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CampaignUsers($filters: UserFilter, $pagination: OffsetPaginationInput) {\n    campaignUsers(filters: $filters, pagination: $pagination) {\n      items {\n        firstName\n        lastName\n        email\n        phoneNumber\n        id\n        lastLogin\n        companyMember {\n          company {\n            name\n          }\n          budget\n        }\n        userThemes {\n          theme {\n            name\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').CampaignUsersDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Segments($pagination: OffsetPaginationInput) {\n    segments(pagination: $pagination) {\n      items {\n        contacts {\n          totalItemsCount\n        }\n        name\n        filters\n        updatedAt\n        id\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').SegmentsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SegmentDetail($id: ID!, $pagination: OffsetPaginationInput, $search: String) {\n    segmentDetail(id: $id) {\n      contacts(pagination: $pagination, search: $search) {\n        totalItemsCount\n        items {\n          firstName\n          lastName\n          email\n          id\n          lastLogin\n          phoneNumber\n          companyMember {\n            company {\n              name\n            }\n            budget\n          }\n          userThemes {\n            theme {\n              name\n            }\n          }\n        }\n      }\n      name\n      filters\n      id\n      updatedAt\n    }\n  }\n',
): typeof import('./graphql').SegmentDetailDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MessageTemplates($search: String, $pagination: OffsetPaginationInput) {\n    campaignMessageTemplates(search: $search, pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        name\n        content\n      }\n    }\n  }\n',
): typeof import('./graphql').MessageTemplatesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Campaigns($search: String, $pagination: OffsetPaginationInput) {\n    campaigns(search: $search, pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        message\n        name\n        sentAt\n        scheduledAt\n        segment {\n          contacts {\n            totalItemsCount\n          }\n          id\n          name\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').CampaignsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation budgetCompanyApproval($input: BudgetCompanyApprovalInput!) {\n    budgetCompanyApproval(input: $input)\n  }\n',
): typeof import('./graphql').BudgetCompanyApprovalDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateCompanyEmployee($input: CreateUserInput!, $budget: Float, $companyId: ID) {\n    createCompanyEmployee(input: $input, budget: $budget, companyId: $companyId)\n  }\n',
): typeof import('./graphql').CreateCompanyEmployeeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateCompanyEmployee($input: CreateUserInput!, $companyId: ID) {\n    updateCompanyEmployee(input: $input, companyId: $companyId)\n  }\n',
): typeof import('./graphql').UpdateCompanyEmployeeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteCompanyEmployee($id: ID!, $companyId: ID) {\n    deleteCompanyEmployee(id: $id, companyId: $companyId)\n  }\n',
): typeof import('./graphql').DeleteCompanyEmployeeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompanyStatistics {\n    companyStatistics {\n      privateEnergy\n      seatsInUse\n      totalBookedServices\n      totalPendingBookings\n      totalSeats\n      workEnergy\n      totalUsersFinishedScan\n      totalFinishedSelfscan\n      totalFinishedCheckin\n      themes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      mostFavoriteThemes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').CompanyStatisticsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FilteredCompanyStatistics($slug: String, $dateRange: StatisticsDateRange = QUARTER) {\n    companyStatistics(slug: $slug, dateRange: $dateRange) {\n      filteredBookedServices\n    }\n  }\n',
): typeof import('./graphql').FilteredCompanyStatisticsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query budgetRequests($pagination: OffsetPaginationInput, $search: String, $status: String) {\n    budgetRequests(pagination: $pagination, search: $search, status: $status) {\n      items {\n        id\n        amount\n        createdAt\n        updatedAt\n        approvedByCompany\n        message\n        product {\n          name\n          slug\n          price\n          child {\n            __typename\n            ... on ServiceType {\n              numberOfSessions\n            }\n            ... on ItemType {\n              id\n            }\n          }\n          vendor {\n            name\n            slug\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n        }\n        employee {\n          budget\n          user {\n            lastName\n            firstName\n          }\n        }\n      }\n      totalItemsCount\n      outstandingItemsCount\n    }\n  }\n',
): typeof import('./graphql').BudgetRequestsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompanyMembers(\n    $slug: String\n    $search: String\n    $selfscan: String\n    $status: String\n    $pagination: OffsetPaginationInput\n  ) {\n    companyMembers(\n      slug: $slug\n      search: $search\n      selfscan: $selfscan\n      status: $status\n      pagination: $pagination\n    ) {\n      totalItemsCount\n      items {\n        id\n        inactive\n        user {\n          email\n          lastName\n          firstName\n          phoneNumber\n          lastLogin\n          userThemes {\n            id\n          }\n        }\n        budget\n      }\n    }\n  }\n',
): typeof import('./graphql').CompanyMembersDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Company {\n    company {\n      totalSeats\n      seatsInUse\n    }\n  }\n',
): typeof import('./graphql').CompanyDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation MarkSupportedUser($userId: ID!) {\n    markSupportedUser(userId: $userId)\n  }\n',
): typeof import('./graphql').MarkSupportedUserDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation BulkUpdateEmployeeBudget($slug: String!, $budget: Float!) {\n    bulkUpdateEmployeeBudget(slug: $slug, budget: $budget)\n  }\n',
): typeof import('./graphql').BulkUpdateEmployeeBudgetDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SynchronizeSendgrid {\n    synchronizeUsersToSendgrid\n    createSendgridSegments\n  }\n',
): typeof import('./graphql').SynchronizeSendgridDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Companies($search: String, $pagination: OffsetPaginationInput) {\n    companies(search: $search, pagination: $pagination) {\n      items {\n        id\n        slug\n        name\n        seatsInUse\n        totalSeats\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').CompaniesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompanyDetail($slug: String!) {\n    companyStatistics(slug: $slug) {\n      company {\n        id\n        name\n        budgetPerEmployee\n        registeredMembers\n      }\n      seatsInUse\n      totalSeats\n      totalBookedServices\n      totalPendingBookings\n      totalUsersFinishedScan\n      totalPendingBudget\n      totalSpendBudget\n      totalAvailableBudget\n      mostFavoriteThemes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      privateEnergy\n      themes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      totalFinishedCheckin\n      totalFinishedSelfscan\n      workEnergy\n    }\n  }\n',
): typeof import('./graphql').CompanyDetailDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserInfo($id: ID!) {\n    userInfo(id: $id) {\n      firstName\n      lastName\n      email\n      companyMember {\n        company {\n          name\n        }\n        workEnergy\n        privateEnergy\n      }\n      userThemes {\n        ...ScoredThemeFragment\n      }\n      nonThemeAnswers {\n        text\n        answer\n      }\n    }\n  }\n',
): typeof import('./graphql').UserInfoDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NeedSupportUsers($pagination: OffsetPaginationInput) {\n    needSupportUsers(pagination: $pagination) {\n      items {\n        id\n        firstName\n        lastName\n        email\n        companyMember {\n          id\n          company {\n            name\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').NeedSupportUsersDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OverviewStatistics {\n    overviewStatistics {\n      totalPendingBookings\n      bookedServices\n      filteredBookings\n      mostFavoriteThemes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      privateEnergy\n      registrations\n      themes {\n        score\n        theme {\n          id\n          slug\n          name\n          color\n          icon {\n            url\n          }\n        }\n      }\n      totalFinishedCheckin\n      totalFinishedSelfscan\n      workEnergy\n      totalSeats\n      seatsInUse\n      totalPendingBudget\n      totalSpendBudget\n      totalAvailableBudget\n    }\n  }\n',
): typeof import('./graphql').OverviewStatisticsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FilteredBookingsOverview($dateRange: StatisticsDateRange = QUARTER) {\n    overviewStatistics(dateRange: $dateRange) {\n      filteredBookings\n    }\n  }\n',
): typeof import('./graphql').FilteredBookingsOverviewDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VendorWithoutUserChats($pagination: OffsetPaginationInput) {\n    vendorWithoutUserChats(pagination: $pagination) {\n      totalItemsCount\n      items {\n        lastSent\n        messages(pagination: { offset: 0, limit: 1 }) {\n          items {\n            content\n          }\n        }\n        participant1 {\n          name\n          email\n          id\n        }\n        participant2 {\n          name\n          email\n          id\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').VendorWithoutUserChatsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NotFollowedChats($pagination: OffsetPaginationInput) {\n    notFollowedChats(pagination: $pagination) {\n      totalItemsCount\n      items {\n        lastSent\n        messages(pagination: { offset: 0, limit: 1 }) {\n          items {\n            content\n            sender {\n              pk\n            }\n          }\n        }\n        participant1 {\n          name\n          email\n          id\n        }\n        participant2 {\n          name\n          email\n          id\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').NotFollowedChatsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ExportScans($excludeIds: String, $pagination: OffsetPaginationInput) {\n    exportScans(excludeIds: $excludeIds, pagination: $pagination) {\n      items {\n        userId\n        organizationId\n        scanType\n        scoredQuestions {\n          id\n          name\n          score\n        }\n        scoredThemes {\n          id\n          name\n          score\n        }\n        date\n        bookedProducts\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').ExportScansDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RegisterWebinar($id: ID!) {\n    registerWebinar(id: $id)\n  }\n',
): typeof import('./graphql').RegisterWebinarDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ThemeFragment on ThemeType {\n    id\n    name\n    slug\n    icon {\n      url\n    }\n    color\n    description\n    highlights {\n      highlightedItem {\n        __typename\n        ...HighLightedContentPageFragment\n        ...VendorFragment\n        ...ProductFragment\n      }\n    }\n  }\n',
): typeof import('./graphql').ThemeFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserThemeFragment on UserThemeType {\n    id\n    theme {\n      ...ThemeFragment\n    }\n    isFavorite\n    score\n  }\n',
): typeof import('./graphql').UserThemeFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Dashboard {\n    dashboard {\n      showScan\n      userThemes {\n        ...UserThemeFragment\n      }\n      themes {\n        ...ThemeFragment\n      }\n      contentPages {\n        ...ContentPageFragment\n      }\n    }\n  }\n',
): typeof import('./graphql').DashboardDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FaqQuery {\n    faqs {\n      id\n      question\n      answer\n    }\n  }\n',
): typeof import('./graphql').FaqQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Webinars($pagination: OffsetPaginationInput) {\n    activeWebinars(pagination: $pagination) {\n      items {\n        id\n        name\n        registered\n        description\n        startAt\n        endAt\n        externalLink\n        image(resize: { fill: { width: 400, height: 300 } }) {\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').WebinarsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AdminBookingApproval($input: ApproveBookingInput!) {\n    adminBookingApproval(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').AdminBookingApprovalDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateBillingStatus(\n    $input: UpdateBillingStatusInput = { companyBillingStatus: PENDING }\n  ) {\n    updateBillingStatus(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').UpdateBillingStatusDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation VendorBookingApproval($input: ApproveBookingInput!) {\n    vendorBookingApproval(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').VendorBookingApprovalDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SuggestBookingDate($input: SuggestDateInput!) {\n    suggestBookingDate(input: $input) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').SuggestBookingDateDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VendorStatistics {\n    vendorStatistics {\n      bookedServices\n      receiveBudget\n    }\n  }\n',
): typeof import('./graphql').VendorStatisticsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Bookings($pagination: OffsetPaginationInput, $search: String, $filters: BookingFilter) {\n    bookings(pagination: $pagination, search: $search, filters: $filters) {\n      items {\n        id\n        status\n        date\n        price\n        createdAt\n        vendorBillingStatus\n        companyBillingStatus\n        employee {\n          id\n          user {\n            firstName\n            lastName\n            email\n            phoneNumber\n          }\n          company {\n            name\n          }\n        }\n        employeeComment\n        product {\n          name\n          slug\n          price\n          child {\n            __typename\n            ... on ServiceType {\n              numberOfSessions\n            }\n            ... on ItemType {\n              id\n            }\n          }\n          vendor {\n            name\n            slug\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n        }\n        actions {\n          date\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').BookingsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SharedUserInfoList($pagination: OffsetPaginationInput, $search: String) {\n    sharedUserInfoList(pagination: $pagination, search: $search) {\n      items {\n        slug\n        user {\n          id\n          firstName\n          lastName\n          email\n          companyMember {\n            company {\n              name\n            }\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').SharedUserInfoListDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FavoriteProducts($pagination: OffsetPaginationInput = {}) {\n    favoriteProducts(pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        name\n        slug\n        description\n        usePlaceholderImage\n        price\n        priceAlterText\n        template\n        image(resize: { max: { width: 1000, height: 1000 } }) {\n          url\n        }\n        images {\n          image(resize: { max: { width: 1000, height: 1000 } }) {\n            url\n          }\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n        child {\n          __typename\n          ... on ItemType {\n            id\n          }\n          ... on ServiceType {\n            id\n            numberOfSessions\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').FavoriteProductsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FavoriteVendors($pagination: OffsetPaginationInput = {}) {\n    favoriteVendors(pagination: $pagination) {\n      totalItemsCount\n      items {\n        name\n        slug\n        id\n        description\n        image(resize: { fill: { width: 480, height: 480 } }) {\n          url\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').FavoriteVendorsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FavoriteContentPages($pagination: OffsetPaginationInput = {}) {\n    favoriteContentPages(pagination: $pagination) {\n      totalItemsCount\n      items {\n        id\n        title\n        slug\n        intro\n        mainImage(resize: { fill: { width: 480, height: 480 } }) {\n          id\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            alt\n            height\n            id\n            url\n            width\n          }\n        }\n        child {\n          __typename\n          ... on BlogType {\n            id\n          }\n          ... on GuideType {\n            id\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').FavoriteContentPagesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Guide($slug: String!) {\n    guide(slug: $slug) {\n      id\n      title\n      slug\n      intro\n      body\n      author\n      chapters {\n        header\n        body\n      }\n      mainImage(resize: { fill: { width: 480, height: 480 } }) {\n        id\n        url\n      }\n      vendor {\n        id\n        name\n        slug\n        smallImage(resize: { fill: { width: 64, height: 64 } }) {\n          alt\n          height\n          id\n          url\n          width\n        }\n      }\n      images(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      footerIcon {\n        url\n      }\n      footerHeader\n      footerText\n      themes {\n        id\n        name\n        icon {\n          url\n        }\n      }\n      tags {\n        id\n        name\n      }\n    }\n  }\n',
): typeof import('./graphql').GuideDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Guides($pagination: OffsetPaginationInput) {\n    guides(pagination: $pagination) {\n      items {\n        id\n        title\n        slug\n        intro\n        mainImage(resize: { fill: { width: 1280, height: 600 } }) {\n          url\n        }\n        vendor {\n          name\n          smallImage(resize: { fill: { width: 64, height: 64 } }) {\n            url\n          }\n        }\n        images(resize: { max: { width: 800, height: 800 } }) {\n          url\n        }\n        child {\n          __typename\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').GuidesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation requestEmailLoginCode($email: Email!) {\n    requestEmailLoginCode(input: { email: $email })\n  }\n',
): typeof import('./graphql').RequestEmailLoginCodeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation requestTextLoginCode($emailCode: String!, $changePhoneNumber: PhoneNumber) {\n    requestTextLoginCode(input: { emailCode: $emailCode, changePhoneNumber: $changePhoneNumber })\n  }\n',
): typeof import('./graphql').RequestTextLoginCodeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation login($textCode: String!, $emailCode: String!) {\n    login(input: { textCode: $textCode, emailCode: $emailCode }) {\n      id\n      email\n      firstName\n      lastName\n      phoneNumber\n      familyStatus\n      gender\n      dateOfBirth\n      language\n      companyMember {\n        company {\n          name\n          slug\n          budgetPerEmployee\n        }\n        isOwner\n        isAdmin\n        isEmployee\n        budget\n        totalBudget\n        workEnergy\n        privateEnergy\n      }\n      vendorMember {\n        vendor {\n          name\n          slug\n          smallImage(resize: { fill: { width: 80, height: 80 } }) {\n            url\n          }\n        }\n      }\n      userThemes {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').LoginDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation logout {\n    logout\n  }\n',
): typeof import('./graphql').LogoutDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation acceptTerms($input: AcceptTermsInput!) {\n    acceptTerms(input: $input) {\n      createdAt\n      version\n      content\n      accepted\n    }\n  }\n',
): typeof import('./graphql').AcceptTermsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation FillRegisterInfo($code: String!, $input: UserInput!) {\n    fillInRegisterInfo(code: $code, input: $input) {\n      ... on UserType {\n        email\n        phoneNumber\n      }\n    }\n  }\n',
): typeof import('./graphql').FillRegisterInfoDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query emailLoginInfo($emailCode: String!) {\n    emailLoginInfo(input: { emailCode: $emailCode }) {\n      user {\n        firstName\n        lastName\n        email\n        phoneNumber\n      }\n      organization {\n        ... on CompanyType {\n          name\n        }\n        ... on VendorType {\n          name\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').EmailLoginInfoDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query me {\n    me {\n      id\n      email\n      firstName\n      lastName\n      phoneNumber\n      familyStatus\n      gender\n      dateOfBirth\n      language\n      isSuperuser\n      isStaff\n      isCustomerSupport\n      lastLogin\n      companyMember {\n        company {\n          logo(resize: { fill: { width: 80, height: 80 } }) {\n            url\n          }\n          name\n          slug\n          budgetPerEmployee\n          companyBudget\n          accountType\n        }\n        isOwner\n        isAdmin\n        isEmployee\n        budget\n        totalBudget\n        workEnergy\n        privateEnergy\n      }\n      vendorMember {\n        vendor {\n          name\n          slug\n          smallImage(resize: { fill: { width: 80, height: 80 } }) {\n            url\n          }\n        }\n      }\n      userThemes {\n        id\n        isFavorite\n        theme {\n          name\n          slug\n          icon(resize: { fill: { width: 32, height: 32 } }) {\n            url\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').MeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TermsQuery {\n    terms {\n      accepted\n      content\n      createdAt\n      version\n    }\n  }\n',
): typeof import('./graphql').TermsQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SendMessage($input: SendMessageInput!) {\n    sendMessage(input: $input) {\n      id\n    }\n  }\n',
): typeof import('./graphql').SendMessageDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ReadChat($chatId: ID!) {\n    readChat(input: { chatId: $chatId }) {\n      id\n    }\n  }\n',
): typeof import('./graphql').ReadChatDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateChat($input: CreateChatInput!) {\n    createChat(input: $input) {\n      id\n      participant2 {\n        name\n      }\n      participant1 {\n        isCurrentUser\n      }\n    }\n  }\n',
): typeof import('./graphql').CreateChatDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation BookingDateApproval($id: Int!) {\n    bookingDateApproval(input: { id: $id, approved: true }) {\n      ... on BookingType {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').BookingDateApprovalDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SendEmailForMessageByAdmin($id: ID!) {\n    sendEmailForMessageByAdmin(id: $id)\n  }\n',
): typeof import('./graphql').SendEmailForMessageByAdminDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SendEmailForMessage($id: ID!) {\n    sendEmailForMessage(id: $id)\n  }\n',
): typeof import('./graphql').SendEmailForMessageDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateMessage($id: ID!, $input: UpdateMessageInput!) {\n    updateMessage(id: $id, input: $input) {\n      id\n    }\n  }\n',
): typeof import('./graphql').UpdateMessageDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateChat($id: ID!, $input: UpdateChatInput!) {\n    updateChat(id: $id, input: $input) {\n      id\n    }\n  }\n',
): typeof import('./graphql').UpdateChatDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ChatContacts($pagination: OffsetPaginationInput, $filters: ChatFilter) {\n    chats(pagination: $pagination, filters: $filters) {\n      items {\n        id\n        participant1 {\n          id\n          name\n          isCurrentUser\n        }\n        participant2 {\n          id\n          name\n        }\n        hasUnreadMessage\n        messages(pagination: { limit: 1 }) {\n          items {\n            content\n            readAt\n            createdAt\n            type\n          }\n        }\n        flagged\n        archived\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').ChatContactsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Messages($chatId: ID!, $pagination: OffsetPaginationInput, $filters: MessageFilter, $search: String) {\n    chat(pk: $chatId) {\n      messages(pagination: $pagination, filters: $filters, search: $search) {\n        items {\n          id\n          direction\n          content\n          createdAt\n          type\n          sentEmailAt\n          readAt\n          archived\n          markedUnread\n          flagged\n          senderEmail\n        }\n        totalItemsCount\n      }\n    }\n  }\n',
): typeof import('./graphql').MessagesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HasUnreadChat {\n    hasUnreadChat {\n      normal\n      fromCustomerSupport\n    }\n  }\n',
): typeof import('./graphql').HasUnreadChatDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Booking($id: ID!) {\n    booking(pk: $id) {\n      status\n    }\n  }\n',
): typeof import('./graphql').BookingDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ChatTemplate {\n    chatTemplates {\n      content\n      id\n    }\n  }\n',
): typeof import('./graphql').ChatTemplateDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query service($slug: String!) {\n    service(slug: $slug) {\n      id\n      name\n      slug\n      description\n      richDescription\n      vendor {\n        ...VendorFragment\n      }\n      template\n      image(resize: { max: { width: 1000, height: 1000 } }) {\n        url\n      }\n      images {\n        image(resize: { max: { width: 1000, height: 1000 } }) {\n          url\n        }\n      }\n      descriptionImages(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      numberOfSessions\n      locationGroup {\n        name\n        locations {\n          id\n          name\n        }\n      }\n      price\n      template\n      usePlaceholderImage\n      priceAlterText\n      languages {\n        code\n      }\n      themes {\n        id\n      }\n      tags {\n        id\n      }\n      __typename\n    }\n  }\n',
): typeof import('./graphql').ServiceDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HighLightedContentPageFragment on ContentPageType {\n    id\n    title\n    slug\n    intro\n    mainImage(resize: { fill: { width: 480, height: 480 } }) {\n      id\n      url\n    }\n    child {\n      __typename\n      ... on BlogType {\n        id\n      }\n      ... on GuideType {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').HighLightedContentPageFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VendorFragment on VendorType {\n    id\n    name\n    slug\n    description\n    city\n    image(resize: { fill: { width: 480, height: 480 } }) {\n      url\n    }\n    smallImage(resize: { fill: { width: 64, height: 64 } }) {\n      url\n    }\n    tags {\n      name\n    }\n    themes {\n      name\n    }\n    locationGroup {\n      name\n    }\n  }\n',
): typeof import('./graphql').VendorFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProductFragment on ProductType {\n    id\n    name\n    slug\n    description\n    price\n    image(resize: { fill: { width: 480, height: 480 } }) {\n      url\n    }\n    usePlaceholderImage\n    vendor {\n      ...VendorFragment\n    }\n    child {\n      __typename\n      ... on ItemType {\n        id\n      }\n      ... on ServiceType {\n        id\n        numberOfSessions\n      }\n    }\n  }\n',
): typeof import('./graphql').ProductFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContentPageFragment on ContentPageType {\n    id\n    title\n    slug\n    intro\n    mainImage(resize: { fill: { width: 480, height: 480 } }) {\n      id\n      url\n    }\n    vendor {\n      name\n      smallImage(resize: { fill: { width: 64, height: 64 } }) {\n        alt\n        height\n        id\n        url\n        width\n      }\n    }\n    child {\n      __typename\n      ... on BlogType {\n        id\n        slug\n      }\n      ... on GuideType {\n        id\n        slug\n      }\n    }\n  }\n',
): typeof import('./graphql').ContentPageFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query pdpData($slug: String!) {\n    productDetailPage(slug: $slug) {\n      name\n      slug\n      score\n      icon {\n        url\n      }\n      descriptionHeader\n      description\n      tags {\n        id\n        name\n        slug\n      }\n      highlights {\n        highlightedItem {\n          ... on ProductType {\n            id\n            __typename\n            child {\n              ... on ItemType {\n                id\n                __typename\n              }\n              ... on ServiceType {\n                id\n                __typename\n              }\n            }\n          }\n          ... on VendorType {\n            id\n            __typename\n          }\n          ... on ContentPageType {\n            id\n            __typename\n            child {\n              ... on BlogType {\n                id\n                __typename\n              }\n              ... on GuideType {\n                id\n                __typename\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').PdpDataDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query products($slug: String!, $filter: ProductFilter, $pagination: OffsetPaginationInput) {\n    productDetailPage(slug: $slug) {\n      products(filters: $filter, pagination: $pagination) {\n        totalItemsCount\n        items {\n          id\n          name\n          slug\n          description\n          price\n          priceAlterText\n          template\n          usePlaceholderImage\n          image(resize: { max: { width: 1000, height: 1000 } }) {\n            url\n          }\n          images {\n            image(resize: { max: { width: 1000, height: 1000 } }) {\n              url\n            }\n          }\n          vendor {\n            name\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n          child {\n            __typename\n            ... on ItemType {\n              id\n            }\n            ... on ServiceType {\n              id\n              numberOfSessions\n            }\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').ProductsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query vendors($slug: String!, $pagination: OffsetPaginationInput) {\n    productDetailPage(slug: $slug) {\n      vendors(pagination: $pagination) {\n        totalItemsCount\n        items {\n          name\n          slug\n          id\n          description\n          image(resize: { fill: { width: 480, height: 480 } }) {\n            url\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').VendorsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query contentPages($slug: String!, $pagination: OffsetPaginationInput) {\n    productDetailPage(slug: $slug) {\n      contentPages(pagination: $pagination) {\n        totalItemsCount\n        items {\n          ...ContentPageFragment\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').ContentPagesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query tags($slug: String!) {\n    productDetailPage(slug: $slug) {\n      productTags {\n        id\n        name\n      }\n    }\n  }\n',
): typeof import('./graphql').TagsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query vendor($slug: String!) {\n    vendor(slug: $slug) {\n      id\n      name\n      description\n      description2\n      description3\n      city\n      locationGroup {\n        name\n      }\n      website\n      languages {\n        code\n      }\n      studies {\n        name\n      }\n      image(resize: { fill: { width: 480, height: 480 } }) {\n        url\n      }\n      smallImage(resize: { fill: { width: 64, height: 64 } }) {\n        url\n      }\n      tags {\n        id\n        slug\n        name\n      }\n      themes {\n        id\n        slug\n        name\n      }\n    }\n  }\n',
): typeof import('./graphql').VendorDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VendorProducts($vendorId: ID, $pagination: OffsetPaginationInput) {\n    products(\n      excludeWebinar: true\n      filters: { vendor: { id: $vendorId } }\n      pagination: $pagination\n    ) {\n      items {\n        id\n        name\n        slug\n        description\n        price\n        template\n        priceAlterText\n        usePlaceholderImage\n        image(resize: { fill: { width: 480, height: 480 } }) {\n          url\n        }\n        images {\n          image(resize: { max: { width: 1000, height: 1000 } }) {\n            url\n          }\n        }\n        child {\n          __typename\n          ... on ItemType {\n            id\n          }\n          ... on ServiceType {\n            id\n            numberOfSessions\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').VendorProductsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ProductItem($slug: String!) {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      description\n      richDescription\n      vendor {\n        ...VendorFragment\n      }\n      template\n      image(resize: { max: { width: 1000, height: 1000 } }) {\n        url\n      }\n      images {\n        image(resize: { max: { width: 1000, height: 1000 } }) {\n          url\n        }\n      }\n      descriptionImages(resize: { max: { width: 800, height: 800 } }) {\n        baseImageId\n        url\n      }\n      price\n      priceAlterText\n      usePlaceholderImage\n      template\n      themes {\n        id\n      }\n      tags {\n        id\n      }\n    }\n  }\n',
): typeof import('./graphql').ProductItemDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateInfoMutation($input: UserInput!) {\n    updateProfile(input: $input) {\n      ... on UserType {\n        email\n        phoneNumber\n      }\n    }\n  }\n',
): typeof import('./graphql').UpdateInfoMutationDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateFavoriteTheme($input: UpdateThemeInput!) {\n    updateFavoriteTheme(input: $input)\n  }\n',
): typeof import('./graphql').UpdateFavoriteThemeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ScoredThemeFragment on UserThemeType {\n    id\n    theme {\n      id\n      name\n      slug\n      order\n      description\n      color\n      icon {\n        url\n      }\n    }\n    isFavorite\n    score\n    scanType\n    answers {\n      answer\n      text\n    }\n  }\n',
): typeof import('./graphql').ScoredThemeFragmentFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserThemes {\n    me {\n      userThemes {\n        ...ScoredThemeFragment\n      }\n      nonThemeAnswers {\n        text\n        answer\n      }\n    }\n  }\n',
): typeof import('./graphql').UserThemesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TimeLine($pagination: OffsetPaginationInput, $lte: Date) {\n    timeline(pagination: $pagination, filters: { date: { lte: $lte } }) {\n      totalItemsCount\n      items {\n        id\n        date\n        image(resize: { fill: { width: 100, height: 100 } }) {\n          url\n        }\n        itemType\n        title\n      }\n    }\n  }\n',
): typeof import('./graphql').TimeLineDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation StartScan($input: StartScanInput!) {\n    startScan(input: $input)\n  }\n',
): typeof import('./graphql').StartScanDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation FinishScan($input: FinishScanInput!) {\n    finishScan(input: $input) {\n      id\n      needSupport\n    }\n  }\n',
): typeof import('./graphql').FinishScanDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AnswerBinaryQuestion($input: BinaryAnswerInput!) {\n    answerBinaryQuestion(input: $input)\n  }\n',
): typeof import('./graphql').AnswerBinaryQuestionDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AnswerMultipleChoiceQuestion($input: MultipleChoiceAnswerInput!) {\n    answerMultipleChoiceQuestion(input: $input)\n  }\n',
): typeof import('./graphql').AnswerMultipleChoiceQuestionDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AnswerScoreQuestion($input: ScoreAnswerInput!) {\n    answerScoreQuestion(input: $input)\n  }\n',
): typeof import('./graphql').AnswerScoreQuestionDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AnswerSliderQuestion($input: SliderAnswerInput!) {\n    answerSliderQuestion(input: $input)\n  }\n',
): typeof import('./graphql').AnswerSliderQuestionDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AnswerTextQuestion($input: TextAnswerInput!) {\n    answerTextQuestion(input: $input)\n  }\n',
): typeof import('./graphql').AnswerTextQuestionDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SelfScan {\n    selfScan {\n      id\n      introHeader\n      introText\n      outroHeader\n      outroText\n      questions(pagination: { limit: 100 }) {\n        items {\n          id\n          description\n          title\n          type\n          image(resize: { fill: { width: 600, height: 800 } }) {\n            url\n          }\n          child {\n            ... on ScoreQuestionType {\n              id\n              maxScore\n            }\n            ... on MultipleChoiceQuestionType {\n              id\n              choices {\n                weight\n                text\n              }\n            }\n            ... on BinaryQuestionType {\n              id\n            }\n            ... on SliderQuestionType {\n              id\n              maxScore\n            }\n            ... on TextQuestionType {\n              id\n            }\n          }\n        }\n        totalItemsCount\n      }\n    }\n  }\n',
): typeof import('./graphql').SelfScanDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CheckIn {\n    checkIn {\n      id\n      introHeader\n      introText\n      outroHeader\n      outroText\n      questions(pagination: { limit: 100 }) {\n        items {\n          id\n          description\n          title\n          type\n          image(resize: { fill: { width: 600, height: 800 } }) {\n            url\n          }\n          child {\n            ... on ScoreQuestionType {\n              id\n              maxScore\n            }\n            ... on MultipleChoiceQuestionType {\n              id\n              choices {\n                weight\n                text\n              }\n            }\n            ... on BinaryQuestionType {\n              id\n            }\n            ... on SliderQuestionType {\n              id\n              maxScore\n            }\n            ... on TextQuestionType {\n              id\n            }\n          }\n        }\n        totalItemsCount\n      }\n    }\n  }\n',
): typeof import('./graphql').CheckInDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchQuery(\n    $pagination: OffsetPaginationInput\n    $search: String!\n    $filters: SearchItemFilter = {}\n    $ignoreMixContent: Boolean = false\n    $prioritizedOrder: [String!]\n    $isTeamOffer: Boolean = false\n  ) {\n    search(\n      search: $search\n      pagination: $pagination\n      filters: $filters\n      ignoreMixContent: $ignoreMixContent\n      prioritizedOrder: $prioritizedOrder\n      isTeamOffer: $isTeamOffer\n    ) {\n      items {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').SearchQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MiniSearchQuery($search: String!) {\n    miniSearch(search: $search) {\n      blogs {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n      products {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n      experts {\n        id\n        text\n        textEn\n        category\n        image {\n          url\n        }\n        isBook\n        itemId\n        numberOfSessions\n        order\n        price\n        priceAlterText\n        usePlaceholderImage\n        vendor {\n          name\n          smallImage {\n            url\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').MiniSearchQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TagQuery {\n    tags {\n      name\n      slug\n      id\n    }\n  }\n',
): typeof import('./graphql').TagQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchHistoryQuery($search: String!) {\n    searchHistory(search: $search) {\n      id\n      search\n      updatedAt\n    }\n  }\n',
): typeof import('./graphql').SearchHistoryQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ApproveRequest($slug: String!) {\n    shareInfoApproval(input: { slug: $slug, status: APPROVED })\n  }\n',
): typeof import('./graphql').ApproveRequestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RejectRequest($slug: String!) {\n    shareInfoApproval(input: { slug: $slug, status: REFUSED })\n  }\n',
): typeof import('./graphql').RejectRequestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShareUserInfo($slug: String!) {\n    sharedUserInfo(slug: $slug) {\n      message\n      status\n      vendors {\n        name\n      }\n      user {\n        firstName\n        lastName\n        email\n        companyMember {\n          company {\n            name\n          }\n          workEnergy\n          privateEnergy\n        }\n        userThemes {\n          ...ScoredThemeFragment\n        }\n        nonThemeAnswers {\n          text\n          answer\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').ShareUserInfoDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TagPage($slug: String!) {\n    tagPage(slug: $slug) {\n      tag {\n        name\n        slug\n      }\n      icon {\n        url\n      }\n      descriptionHeader\n      description\n      highlights {\n        highlightedItem {\n          __typename\n          ...HighLightedContentPageFragment\n          ...VendorFragment\n          ...ProductFragment\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').TagPageDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query tagProducts($slug: String!, $filter: ProductFilter, $pagination: OffsetPaginationInput) {\n    tagPage(slug: $slug) {\n      products(filters: $filter, pagination: $pagination) {\n        totalItemsCount\n        items {\n          id\n          name\n          slug\n          description\n          price\n          priceAlterText\n          template\n          usePlaceholderImage\n          image(resize: { fill: { width: 480, height: 480 } }) {\n            url\n          }\n          images {\n            image(resize: { max: { width: 1000, height: 1000 } }) {\n              url\n            }\n          }\n          vendor {\n            name\n            smallImage(resize: { fill: { width: 64, height: 64 } }) {\n              url\n            }\n          }\n          child {\n            __typename\n            ... on ItemType {\n              id\n            }\n            ... on ServiceType {\n              id\n              numberOfSessions\n            }\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').TagProductsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query tagVendors($slug: String!, $pagination: OffsetPaginationInput) {\n    tagPage(slug: $slug) {\n      vendors(pagination: $pagination) {\n        totalItemsCount\n        items {\n          name\n          slug\n          id\n          description\n          image(resize: { fill: { width: 480, height: 480 } }) {\n            url\n          }\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').TagVendorsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query tagContentPages($slug: String!, $pagination: OffsetPaginationInput) {\n    tagPage(slug: $slug) {\n      contentPages(pagination: $pagination) {\n        totalItemsCount\n        items {\n          ...ContentPageFragment\n        }\n      }\n    }\n  }\n',
): typeof import('./graphql').TagContentPagesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VendorsOverview($pagination: OffsetPaginationInput, $filters: VendorListFilter ) {\n    vendors(pagination: $pagination, filters: $filters) {\n      items {\n        id\n        name\n        slug\n        description\n        image {\n          url\n        }\n      }\n      totalItemsCount\n    }\n  }\n',
): typeof import('./graphql').VendorsOverviewDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AllTagsQuery {\n    tags {\n      name\n      id\n      slug\n    }\n  }\n',
): typeof import('./graphql').AllTagsQueryDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AllThemesQuery {\n    themes {\n      id\n      name\n      slug\n    }\n  }\n',
): typeof import('./graphql').AllThemesQueryDocument

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}
