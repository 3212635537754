import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import { ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useEffect, useState } from 'react'
import { Input } from '../ui/input'
import { useTranslation } from 'react-i18next'
import { padStart } from 'lodash'
import usePagination from '@/hooks/usePagination'

const DataTable = <T,>({
  data,
  columns,
  enableSelection,
  showColumnFilter,
  showEmailFilter,
  onRowSelectionChange,
  totalCount,
}: {
  data: T[]
  columns: ColumnDef<T>[]
  showEmailFilter?: boolean
  showColumnFilter?: boolean
  enableSelection?: boolean
  onRowSelectionChange?: (rows: any[]) => void
  totalCount: number
}) => {
  const { t } = useTranslation()
  const { page, limit, updateSearchParams } = usePagination()

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: page || 0,
    pageSize: limit || 25,
  })

  useEffect(() => {
    if (!pagination) return
    updateSearchParams(pagination.pageIndex.toString(), pagination.pageSize.toString())
  }, [JSON.stringify(pagination)])

  const table = useReactTable({
    data,
    columns: enableSelection
      ? [
          {
            id: 'select',
            header: ({ table }) => (
              <Checkbox
                checked={table.getIsAllPageRowsSelected()}
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
              />
            ),
            enableSorting: false,
            enableHiding: false,
          },
          ...columns,
        ]
      : columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  useEffect(() => {
    onRowSelectionChange?.(table.getSelectedRowModel().rows.map((row) => row.original))
  }, [table.getSelectedRowModel().rows.length])

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        {showEmailFilter && (
          <Input
            placeholder="Filter emails..."
            value={(table.getColumn('email')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('email')?.setFilterValue(event.target.value)}
            className="max-w-xs [&>.labels]:hidden"
          />
        )}
        {showColumnFilter && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto" size="sm">
                Columns <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    >
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      <div className="rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex flex-col items-center justify-between gap-2 space-x-2 py-6 md:flex-row">
        <div className="rounded-lg border border-deep-cove/10 px-5 py-2 text-sm font-medium">
          <span className="mr-3">{t('label.page')}</span>
          <span className="mr-1.5">{padStart((pagination.pageIndex + 1).toString(), 2, '0')}</span>
          <span className="text-deep-cove/50">
            / {padStart(Math.ceil(totalCount / pagination.pageSize).toString(), 2, '0')}
          </span>
        </div>
        <div className="flex items-center gap-6">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="flex cursor-pointer items-center gap-1.5 text-sm font-medium">
                {t('label.show')}
                <span>{pagination.pageSize}</span>
                {t('label.per_page')}
                <ChevronRight size={18} className="rotate-90 text-deep-cove/30" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {[25, 50, 100].map((e) => (
                <DropdownMenuCheckboxItem
                  className="justify-end px-6"
                  key={e}
                  onClick={() => setPagination((v) => ({ pageIndex: 0, pageSize: e }))}
                >
                  {e}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            className="bg-deep-cove/5 text-deep-cove"
            variant="outline"
            size="sm"
            onClick={() =>
              setPagination((pagination) => ({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              }))
            }
            disabled={pagination.pageIndex === 0}
          >
            <ChevronLeft size={18} />
            {t('label.previous')}
          </Button>
          <Button
            className="bg-deep-cove/5 text-deep-cove"
            variant="outline"
            size="sm"
            onClick={() => {
              return setPagination((pagination) => ({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              }))
            }}
            disabled={(pagination.pageIndex + 1) * pagination.pageSize >= totalCount}
          >
            {t('label.next')}
            <ChevronRight size={18} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DataTable
