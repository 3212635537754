import { useQueryGraphQL } from '@/use-graphql'
import { miniSearchQuery, searchHistoryQuery, tagsQuery } from '../graphql/query'
import useAuthStore from '@/stores/useAuthStore'
import { ThemesQueryQuery } from '@/gql/graphql'
import { Link } from 'react-router-dom'
import { routes } from '@/routes/utils'
import { colorVariants } from '@/pages/pdp-theme/components/ThemeTagsFilter'
import { Tag } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import ProductSearchResult from './ProductSearchResult'
import PlaceholderImage from '@/assets/images/image-placeholder.jpg'
import ThemeLogo from '@/components/theme-logo/ThemeLogo'
import BlogSearchResult from './BlogSearchResult'
import { getThemeLink } from '@/utils/helper'

export const getHighlightedText = (text, highlight, reverseHighlight = false) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  if (reverseHighlight) {
    return (
      <span>
        {parts.map((part) =>
          part.toLowerCase() === highlight.toLowerCase() ? part : <b key={part}>{part}</b>,
        )}
      </span>
    )
  }
  return (
    <span>
      {parts.map((part) =>
        part.toLowerCase() === highlight.toLowerCase() ? <b key={part}>{part}</b> : part,
      )}
    </span>
  )
}

const SearchPopup = ({
  debounceSearch,
  themes,
  setOpenPopup,
}: {
  debounceSearch: string
  themes: ThemesQueryQuery['themes']
  setOpenPopup: (v: boolean) => void
}) => {
  const { t } = useTranslation()
  const { user, accountType } = useAuthStore()
  const shouldSearch = debounceSearch.length > 1
  const { data } = useQueryGraphQL(
    ['search-history'],
    searchHistoryQuery,
    {
      search: debounceSearch,
    },
    { enabled: shouldSearch },
  )
  const { data: miniSearchResponse } = useQueryGraphQL(
    ['mini-search'],
    miniSearchQuery,
    {
      search: debounceSearch || '',
    },
    { staleTime: 30 * 1000, enabled: shouldSearch },
  )
  const { data: tagsResponse } = useQueryGraphQL(
    ['tags'],
    tagsQuery,
    {},
    { staleTime: 30 * 60 * 1000, cacheTime: 60 * 60 * 1000 },
  )

  const products = miniSearchResponse?.miniSearch?.products || []
  const blogs = miniSearchResponse?.miniSearch?.blogs || []
  const experts = miniSearchResponse?.miniSearch?.experts || []

  const searchWords = debounceSearch.toLowerCase().trim().split(/\s+/)
  const matchedThemes = shouldSearch
    ? themes.filter((e) => {
        return searchWords.some((word) => e.name.toLowerCase().includes(word))
      })
    : []
  const matchedTags = shouldSearch
    ? tagsResponse?.tags?.filter?.((e) => {
        return searchWords.some((word) => e.name.toLowerCase().includes(word))
      }) || []
    : []

  return (
    <div
      className="z-0 h-full w-full overflow-auto rounded-b-xl bg-search md:absolute md:top-10 md:h-[80vh]"
      style={{
        boxShadow: '0px 10px 40px 0px #00000033',
      }}
      onClick={() => setOpenPopup(false)}
    >
      <div className="mt-1 h-[1px] w-full bg-deep-cove/5" />
      {(matchedThemes.length > 0 || matchedTags.length > 0) && (
        <div className="flex gap-2 overflow-x-auto border-t border-deep-cove/5 px-3 py-5 md:flex-wrap md:overflow-x-hidden">
          {matchedThemes.map((item, index) => (
            <Link
              to={getThemeLink(item.slug, item.id, accountType)}
              key={item.id}
              className="block whitespace-nowrap rounded-lg px-3 py-2 text-xs font-medium"
              style={{ background: colorVariants[index % colorVariants.length] }}
            >
              {item.name}
            </Link>
          ))}
          {matchedTags.map((item, index) => (
            <Link
              to={`${routes.searchPage.getPath()}?tag=${item.id}`}
              key={item.id}
              className="flex items-center gap-1 rounded-lg px-3 py-2 text-xs font-medium"
              style={{
                background: colorVariants[(index + matchedThemes.length) % colorVariants.length],
              }}
            >
              <Tag size={12} /> {item.name}
            </Link>
          ))}
        </div>
      )}
      {!!data?.searchHistory.length && (
        <div className="border-t border-deep-cove/5 py-5 text-sm">
          {!shouldSearch && <h3 className="mb-2 px-5 font-bold">{t('label.previous_searches')}</h3>}
          {data?.searchHistory.map((item) => (
            <Link
              to={`${routes.searchPage.getPath()}?q=${item.search}`}
              key={item.id}
              className="block px-5 py-2 hover:bg-deep-cove/5"
            >
              {shouldSearch ? getHighlightedText(item.search, debounceSearch, true) : item.search}
            </Link>
          ))}
        </div>
      )}
      {!shouldSearch && (
        <div className="border-t border-deep-cove/5 py-4 text-sm">
          <h3 className="mb-2 px-5 font-bold">{t('label.relevant_themes')}</h3>
          {user?.userThemes
            ?.filter((e) => e.isFavorite)
            .map((item) => (
              <Link
                to={getThemeLink(item.theme.slug, item.theme.id, accountType)}
                className="flex w-full items-center justify-between px-5 py-1.5 hover:bg-deep-cove/5"
                key={item.id}
              >
                <p key={item.id}>{item?.theme?.name}</p>
                <ThemeLogo url={item.theme.icon?.url || ''} color="#A0A0A0" className="h-4 w-4" />
              </Link>
            ))}
        </div>
      )}
      {shouldSearch && (
        <>
          {!!products.length && (
            <div className="space-y-3 border-t border-deep-cove/5 px-5 py-5 text-sm">
              {products.map((item) => (
                <ProductSearchResult key={item.id} item={item} />
              ))}
            </div>
          )}

          {!!blogs.length && (
            <div className="border-t border-deep-cove/5 py-5 text-sm">
              <h3 className="mb-2 px-5 font-bold">{t('label.relevant_articles')}</h3>
              {blogs.map((item) => (
                <BlogSearchResult key={item.id} item={item} debounceSearch={debounceSearch} />
              ))}
            </div>
          )}

          {!!experts.length && (
            <div className="mx-auto flex w-full gap-3 overflow-x-auto border-t border-deep-cove/5 px-3.5 py-5 text-sm">
              {experts.map((item) => (
                <Link
                  to={routes.vendorDetail.getPath(item.text.split('___')[0])}
                  key={item.id}
                  className="rounded-lg bg-westar/75 px-5 py-4"
                >
                  <img
                    src={item.vendor?.smallImage?.url || PlaceholderImage}
                    alt={item.vendor?.name}
                    className="h-[86px] w-[86px] rounded-md object-cover object-center"
                  />
                  <h3 className="mt-4 w-[86px] truncate text-center text-sm font-medium">
                    {item.vendor?.name}
                  </h3>
                </Link>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SearchPopup
