import * as React from 'react'

import { cn } from '@/utils/shadcn'
import { Label } from './label'
import { XCircle } from 'lucide-react'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  inputClassName?: string
  icon?: React.ReactNode
  onEnter?: () => void
  hideLabel?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { id, label, error, className, inputClassName, type, icon, onEnter, hideLabel, ...props },
    ref,
  ) => {
    // Handle key press event
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onEnter) {
        onEnter()
      }
    }

    return (
      <div className={cn('grid w-full items-center gap-1.5', className)}>
        {!hideLabel && (
          <div className="labels flex justify-between">
            <Label htmlFor={id} className={label ? 'opacity-100' : 'opacity-0'}>
              {label || 'Input Label'}
            </Label>
            <Label
              className={cn('text-brand-valencia opacity-0 transition duration-300 ease-in-out', {
                'opacity-1': !!error,
              })}
            >
              {error}
            </Label>
          </div>
        )}
        <div className="relative">
          {icon && <div className={cn("absolute -top-2 left-3", {'top-3': hideLabel})}>{icon}</div>}
          <input
            id={id}
            type={type}
            className={cn(
              'flex h-11 w-full rounded-md border border-grey-medium bg-background p-3 text-base  text-primary caret-caret ring-offset-background transition duration-300 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground hover:border-brand-torea focus-visible:border-brand-torea focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
              {
                '!border-brand-valencia': !!error,
                'pl-10': icon,
              },
              inputClassName,
            )}
            onKeyUp={handleKeyPress} // Add key press event listener
            ref={ref}
            {...props}
          />
          {error && <XCircle size={18} className="absolute right-3 top-3 text-brand-valencia" />}
        </div>
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
