import { motion } from 'framer-motion'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar from '@/components/navbar/Navbar'
import Smiley from '@/components/smiley/Smiley'
import useAuthStore from '@/stores/useAuthStore'
import { useQueryGraphQL } from '@/use-graphql'
import { hasUnreadChatQuery } from '@/pages/messages/graphql/query'
import useMessageStore from '@/stores/useMessageStore'
import { routes } from '@/routes/utils'
import { cn } from '@/utils/shadcn'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { CircleX, Zap } from 'lucide-react'
import RightArrow from '@/assets/icons/RightArrow'

const hiddenSmileyPaths = [
  routes.selfscan.getPath(),
  routes.checkIn.getPath(),
  routes.messages.getPath(),
]

const AuthLayout = () => {
  const { t } = useTranslation()
  const { isStaff, user } = useAuthStore()
  const { activeChat } = useMessageStore()
  const { pathname } = useLocation()
  const hideSmiley = hiddenSmileyPaths.includes(pathname)
  const showedStickerInLS = localStorage.getItem(`user_${user?.id}_showed_sticker`)
  const [showedSticker, setShowedSticker] = useState(!!showedStickerInLS)

  const { data: hasUnreadChatData } = useQueryGraphQL(
    ['has-unread-chat'],
    hasUnreadChatQuery,
    {},
    { refetchInterval: 10000, enabled: !activeChat },
  )

  return (
    <div className={cn('overflow-x-clip')}>
      {!showedSticker && (
        <div className="fixed top-0 z-50 flex h-12 w-full items-center justify-between bg-brand-cream px-2 md:px-8">
          <div className="flex gap-8 text-xs/[28px] md:text-sm/[28px]">
            <p className="hidden items-center gap-4 font-semibold md:flex">
              <Zap size={18} /> {t('label.welcome_new_platform')}
            </p>
            <a
              href="https://app2.thrivepeople.nl/blogs/welkom-op-ons-nieuwe-platform-thrive-20"
              target="_blank"
              className="flex items-center gap-4 hover:text-brand-torea"
            >
              {t('link.new_platform_cta')} <RightArrow />
            </a>
          </div>
          <button
            onClick={() => {
              setShowedSticker(true)
              localStorage.setItem(`user_${user?.id}_showed_sticker`, 'true')
            }}
            className="flex items-center gap-4 text-sm hover:text-brand-valencia"
          >
            <CircleX size={16} />
            <span className="hidden lg:inline">{t('button.close')}</span>
          </button>
        </div>
      )}
      <Navbar
        hasUnreadChat={!!hasUnreadChatData?.hasUnreadChat.normal}
        className={!showedSticker ? 'top-12' : ''}
      />
      <motion.div
        initial={{ y: 25, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
        className={cn('min-h-screen pt-14 lg:pt-20', {
          'pt-[104px] lg:pt-[124px]': !showedSticker,
        })}
      >
        <Outlet />
      </motion.div>
      {isStaff() || user?.isCustomerSupport ? null : (
        <Smiley
          hasUnreadChat={!!hasUnreadChatData?.hasUnreadChat.fromCustomerSupport}
          hidden={hideSmiley}
        />
      )}
    </div>
  )
}

export default AuthLayout
