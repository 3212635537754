import { CompanyMemberType, ProductsProductPriceAlterTextEnum, SearchSearchItemCategoryEnum, UserType, UsersUserFamilyStatusEnum, UsersUserGenderEnum } from '@/gql/graphql'
import i18next from 'i18next'

export function getDisplayCharacters(str: string, count = 2) {
  const matches = str.match(/\b(\w)/g)
  return matches?.slice(0, count).join('.')
}

export function renderSessionText(numOfSession?: number) {
  return !numOfSession
    ? 'label.per_purchase'
    : numOfSession > 1
    ? 'label.sessions'
    : 'label.session'
}

export function parseFloatLocale(value: string) {
  return parseFloat(value).toLocaleString(i18next.language)
}

export function getVideoIdFromYoutubeUrl(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  return match && match[2].length === 11 ? match[2] : null
}

export const getGenderLabel = (t: any, input: UsersUserGenderEnum) =>
  ({
    [UsersUserGenderEnum.Male]: t('label.male'),
    [UsersUserGenderEnum.Female]: t('label.female'),
    [UsersUserGenderEnum.Other]: t('label.other'),
    [UsersUserGenderEnum.NotSay]: t('label.not_say'),
  }[input])

export const getFamilyStatusLabel = (t: any, input: UsersUserFamilyStatusEnum) =>
  ({
    [UsersUserFamilyStatusEnum.InRelationshipNoChildren]: t('label.in_relationship_no_children'),
    [UsersUserFamilyStatusEnum.InRelationshipWithChildren]: t(
      'label.in_relationship_with_children',
    ),
    [UsersUserFamilyStatusEnum.Single]: t('label.single'),
    [UsersUserFamilyStatusEnum.SingleParent]: t('label.single_parent'),
  }[input])

export const transformErrorMessage = (error: any) => {
  if (typeof error?.message === 'string') {
    return error?.message.split('::').pop()
  }
  return 'Something went wrong'
}

export const renderPriceAlterText = (t, value?: ProductsProductPriceAlterTextEnum | null) => {
  if (!value) {
    return ''
  }
  if (value === ProductsProductPriceAlterTextEnum.Free) {
    return t('label.price_free')
  }
  if (value === ProductsProductPriceAlterTextEnum.OnRequest) {
    return t('label.price_on_request')
  }
  return ''
}

export const renderOrganizationInfo = (user: UserType) => {
  if (user.companyMember) {
    return 'Company: ' + user.companyMember.company.name
  }
  if (user.vendorMember) {
    return 'Vendor: ' + user.vendorMember.vendor.name
  }
  return ''
}

export const getInfoFromSearchTextResult = (text: string) => {
  const [slug, title, description] = text.split('___')
  return { slug, title, description }
}

export const getSearchCategoryLabel = (t: any, input: SearchSearchItemCategoryEnum) =>
  ({
    [SearchSearchItemCategoryEnum.Product]: t('label.product'),
    [SearchSearchItemCategoryEnum.Service]: t('label.service'),
    book: t('label.book'),
    [SearchSearchItemCategoryEnum.Expert]: t('label.expert'),
    [SearchSearchItemCategoryEnum.Blog]: t(
      'label.blog',
    ),
    [SearchSearchItemCategoryEnum.Guide]: t('label.guide'),
  }[input])

const highlightTypeMapper = {
  'ItemType': 'PRODUCT',
  'ServiceType': 'SERVICE',
  'BlogType': 'BLOG',
  'GuideType': 'GUIDE',
  'VendorType': 'EXPERT',
}
export const generatePriorityOrdersForHighlightedItems = (items): string[] => {
  return (items || []).map(e => {
    const highlightItem = e.highlightedItem
    if (highlightItem.child) {
      return `${highlightTypeMapper[highlightItem.child.__typename]}__${highlightItem.id}`
    }
    return (`${highlightTypeMapper[highlightItem.__typename]}__${highlightItem.id}`)
  })
}

export const getEmployeeName = (employee: any) => {
  if (!employee) return ''
  return `${employee.user.firstName} ${employee.user.lastName}`
}
