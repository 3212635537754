import { formatDateTimeForMessage } from '@/utils/datetime'
import { cn } from '@/utils/shadcn'
import Avatar from '../Avatar'
import SmileImage from '@/assets/images/SmileImage.png'

const MessageItem = ({
  isSender,
  text,
  date,
}: {
  text: string
  isSender?: boolean
  date?: string
}) => {
  return (
    <div className={cn('w-10/12 max-w-md self-start', { 'self-end': isSender })}>
      <div className="flex w-full gap-2">
        {!isSender && <Avatar src={SmileImage} />}
        <div
          className={cn('w-full whitespace-pre-line rounded bg-secondary-aqua p-2.5 text-sm', {
            'bg-brand-torea text-white': isSender,
          })}
        >
          {text}
        </div>
      </div>

      <p className={cn('mt-1 text-xs text-grey-dark xl:text-right', { 'text-right': isSender })}>
        {date && formatDateTimeForMessage(new Date(date))}
      </p>
    </div>
  )
}

export default MessageItem
