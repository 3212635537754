import { graphql } from '@/gql'

export const searchQuery = graphql(`
  query SearchQuery(
    $pagination: OffsetPaginationInput
    $search: String!
    $filters: SearchItemFilter = {}
    $ignoreMixContent: Boolean = false
    $prioritizedOrder: [String!]
    $isTeamOffer: Boolean = false
  ) {
    search(
      search: $search
      pagination: $pagination
      filters: $filters
      ignoreMixContent: $ignoreMixContent
      prioritizedOrder: $prioritizedOrder
      isTeamOffer: $isTeamOffer
    ) {
      items {
        id
        text
        textEn
        category
        image {
          url
        }
        isBook
        itemId
        numberOfSessions
        order
        price
        priceAlterText
        usePlaceholderImage
        vendor {
          name
          smallImage {
            url
          }
        }
      }
      totalItemsCount
    }
  }
`)

export const miniSearchQuery = graphql(`
  query MiniSearchQuery($search: String!) {
    miniSearch(search: $search) {
      blogs {
        id
        text
        textEn
        category
        image {
          url
        }
        isBook
        itemId
        numberOfSessions
        order
        price
        priceAlterText
        usePlaceholderImage
        vendor {
          name
          smallImage {
            url
          }
        }
      }
      products {
        id
        text
        textEn
        category
        image {
          url
        }
        isBook
        itemId
        numberOfSessions
        order
        price
        priceAlterText
        usePlaceholderImage
        vendor {
          name
          smallImage {
            url
          }
        }
      }
      experts {
        id
        text
        textEn
        category
        image {
          url
        }
        isBook
        itemId
        numberOfSessions
        order
        price
        priceAlterText
        usePlaceholderImage
        vendor {
          name
          smallImage {
            url
          }
        }
      }
    }
  }
`)

export const tagsQuery = graphql(`
  query TagQuery {
    tags {
      name
      slug
      id
    }
  }
`)

export const searchHistoryQuery = graphql(`
  query SearchHistoryQuery($search: String!) {
    searchHistory(search: $search) {
      id
      search
      updatedAt
    }
  }
`)
