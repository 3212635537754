import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils/shadcn'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-lg text-sm font-base focus-visible:outline-none disabled:pointer-events-none disabled:opacity-60 gap-2.5',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground [&>svg]:transition [&>svg]:hover:text-brand-cream',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent',
        secondary: 'bg-secondary text-secondary-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground [&>svg]:hover:text-brand-torea',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'px-8 py-3',
        sm: 'py-1.5 px-3',
        lg: 'py-6 px-8',
        icon: 'h-8 w-8 md:h-12 md:w-12 shrink-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
