import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-querybuilder/dist/query-builder.scss'
import * as Sentry from '@sentry/react'

import AnimatedRoutes from '@/routes'
import Loading from './components/Loading'
import useLoadingStore from './stores/useLoadingStore'
import ScrollToTop from './components/ScrollToTop'
import useAuthStore from './stores/useAuthStore'
import { ErrorMessage } from './components/ErrorMessage'
import ModalContainer from './features/modal/ModalContainer'
import { useMutationGraphQL, useQueryGraphQL } from './use-graphql'
import { favoritesQuery } from './features/favorite/graphql/query'
import useFavoriteStore from './stores/useFavoriteStore'
import { useEffect } from 'react'
import { add, isAfter, isSameDay } from 'date-fns'
import { updateInfoMutation } from './pages/profile/graphql/mutation'
import { useNavigate } from 'react-router-dom'
import { routes } from './routes/utils'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retry: 0,
      // @ts-ignore
      onError: (error: any) => {
        if (error.message.startsWith('expired_access::')) {
          toast.error('Your account is expired')
          useAuthStore.getState().logout()
        }
      },
    },
  },
})

const AppContent = () => {
  const { user } = useAuthStore()
  const { setItems } = useFavoriteStore()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  useQueryGraphQL(
    ['favorites'],
    favoritesQuery,
    {},
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!user?.companyMember?.isEmployee,
      onSuccess: (data) => setItems(data.favorites),
    },
  )
  const updateLastLoginMutate = useMutationGraphQL(updateInfoMutation, {
    input: { lastLogin: new Date() },
  })

  useEffect(() => {
    if (user) {
      if (isAfter(new Date(), add(new Date(user.lastLogin), { days: 30 }))) {
        return navigate(routes.logout.getPath())
      }
      if (!isSameDay(new Date(user.lastLogin), new Date())) {
        updateLastLoginMutate.mutateAsync()
        queryClient.invalidateQueries({ queryKey: ['current-user'] })
      }
    }
  }, [user])

  return <AnimatedRoutes />
}

function App() {
  const isLoading = useLoadingStore((state) => state.isLoading)

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMessage />}>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <AppContent />
        <ToastContainer autoClose={3000} position="bottom-center" />
        <ModalContainer />
        {isLoading && <Loading />}
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
